// We have 3kinds of factory, value will not be changed based on users' requirement
// Congestion Value are 62% & 44% the same as old Lens:
// How we get .38 and .56  is 1-62%, 1-44%
import { 
  DEFAULT_TECHNOLOGY,
  RVR_SELECTION_KEY,
  RANGE_CONSTANTS_5G,
  RANGE_CONSTANTS_2G,
  FACTOR_COLLECTION_5G,
  FACTOR_COLLECTION_2G
} from "../../../../config/constants";


const xRangeDigit = 1;
// func to do range calculator
export const getFormatRangeCalculateResult = (
  // what we have
  selectedObj,
  eachTC,
  uncommonValues,
  factorCollection
) => {
  // user's input
  let fromattedData: Array<Object> = [];
  const rangeCalculator = selectedObj["rangeCalculator"]
  const txGain = rangeCalculator?.txGain;
  const rxGain = rangeCalculator?.rxGain;
  const shadowFading = rangeCalculator?.shadowFading;
  const desense = rangeCalculator?.desense;
  const frequency = rangeCalculator?.frequency;

  // the same method in our old lens
  const rangeConstant =
    txGain * 1.0 +
    rxGain * 1.0 -
    shadowFading * 1.0 -
    desense * 1.0 -
    20 * Math.log10(frequency * Math.pow(10, 6)) -
    20 * Math.log10(5) +
    147.5;

    const currentObj = selectedObj["data"];

    if (currentObj) {
      const pathLoss = currentObj.path_loss;
      const attenuation = currentObj.attenuation;
      const throughputList = currentObj.throughput_mbps;

      if (currentObj && throughputList && attenuation && pathLoss) {
        const rangeList = throughputList.map((eachtput, index) => {
          return (
            5 *
            Math.pow(
              10,
              (pathLoss[index] * 1.0 +
                attenuation[index] * 1.0 +
                rangeConstant) /
                35
            )
          ).toFixed(xRangeDigit);
        });

        const currentFromatDataArray = rangeCalculate(
          parseInt(eachTC),
          throughputList,
          rangeList,
          factorCollection,
          uncommonValues
        );
        fromattedData = fromattedData.concat(currentFromatDataArray);
      }
    }

  return fromattedData;
};

export const getFormatOutdoorRangeCalculateResult = (
  // what we have
  selectedObj,
  eachTC,
  uncommonValues,
  factorCollection
) => {
  // user's input
  let fromattedData: Array<Object> = [];
  const rangeCalculator = selectedObj["rangeCalculator"]
  const apAntG = rangeCalculator.txGain * 1.0 ;
  const dutAntG = rangeCalculator.rxGain * 1.0 ;
  const dutToDutVariation = rangeCalculator.dutToDutVariation * 1.0 ;
  const belMedian = rangeCalculator.belMedian * 1.0 ;
  const belSigma = rangeCalculator.belSigma * 1.0 ;
  const sfSigma = rangeCalculator.sfSigma * 1.0 ;
  const frequency = rangeCalculator.frequency * 1.0 ;

  const referenceDistance= 5
  const distanceBreakpoint = 1

  const RMS = 10 * Math.log10(Math.sqrt(Math.pow(Math.pow(10,(belSigma / 10)), 2) + Math.pow(Math.pow(10,(sfSigma / 10)), 2)))
  //const RMS =  1 

  const currentObj = selectedObj["data"];

  if (currentObj) {
    const pathLoss = currentObj.path_loss;
    const attenuation = currentObj.attenuation;
    const throughputList = currentObj.throughput_mbps;

    if (currentObj && throughputList && attenuation && pathLoss) {
      const rangeList = throughputList.map((eachtput, index) => {
        // Quip document link for reference: https://quip-amazon.com/G4QaABMy5iwV/Indoor-to-Outdoor-RvR-Lens-Engineering
        const ota = (( (pathLoss[index] * 1.0) + (attenuation[index] * 1.0)) - dutToDutVariation + apAntG + dutAntG) - RMS
        const exponent = ota + 147.5 - belMedian - (20 * Math.log10(referenceDistance / distanceBreakpoint)) - (20 * Math.log10(frequency * Math.pow(10, 6))) 
        return (
          5 * 
          Math.pow(10,(exponent/35))
        ).toFixed(xRangeDigit);
        });

      const currentFromatDataArray = rangeCalculate(
        parseInt(eachTC),
        throughputList,
        rangeList,
        factorCollection,
        uncommonValues
      );
      fromattedData = fromattedData.concat(currentFromatDataArray);
    }

  };

  return fromattedData;
};

const rangeCalculate = (testcaseKey, throughputList, rangeList, factorCollection, uncommonValues) => {
  const fromattedData: Array<Object> = [];
  
  Object.keys(factorCollection).forEach((eachType) => {
    //   format data for each type
    let title = ""
    Object.keys(uncommonValues).forEach((key) => {
      title += uncommonValues[key][testcaseKey]+"-"
    })
    title += `${eachType}`
    const eachTrace = {
      x: rangeList,
      y: throughputList.map((value) =>
        (value * ((100- factorCollection[eachType])/100)).toFixed(4)
      ),
      hovertemplate: "<br><b>range</b>: %{x}<br><i>throughput</i>: %{y}",
      name: title,
      mode: "lines+markers",
      showlegend: true,
      marker: {
        size: 6,
      },
    };

    fromattedData.push(eachTrace);
  });

  return fromattedData;
};


export const compareObjectValues = (selectedData) => {
  /**
  * Compares the values of a selected data object based on a set of keys.
  * Returns common values that are shared across all objects and uncommon values
  * for each key where the values differ.
  * 
  * @param {Object} selectedData - The input object containing data to compare.
  * @returns {Object} - An object with two properties: 
  *   commonValues - An array of values that are common across all objects.
  *   uncommonValues - An object where the keys are the ones that differ across objects, 
  *                    and the values are arrays of the corresponding values for each object.
  */
  const objectValues = Object.values(selectedData); 
  const keys = Object.keys(RVR_SELECTION_KEY); 
  
  const commonValues = [];
  const uncommonValues = {};

  keys.forEach(key => {
    key = key.toLowerCase()
    const firstValue = objectValues?.[0]?.[key];
    const isCommon = objectValues.every(obj => obj[key] === firstValue);

    if (isCommon) {
        commonValues.push(firstValue);
    } else {
      uncommonValues[key] = objectValues.map(obj => obj[key]);
    }
  });

  return { commonValues, uncommonValues };
};

export const findTitle = (selectedData) => {
  const keys = Object.keys(RVR_SELECTION_KEY); // Get the keys from the first object
  let title = []
  keys.forEach(key => {
    title.push(selectedData[key.toLowerCase()]);
  });
  return title.join("_");
};

export const findRangeConstants = (technology,frequency) => {
  var constants = RANGE_CONSTANTS_5G;  
  if(technology.toLowerCase() !== DEFAULT_TECHNOLOGY ){
    constants = {
      ...constants,
      ...RANGE_CONSTANTS_2G
    }
  }
  if(frequency){
    constants["frequency"] = frequency
  }
  return constants;
};

export const breakAfter100Chars = (text) => {
  let result = '';
  for (let i = 0; i < text.length; i += 100) {
      result += text.slice(i, i + 100) + '<br>';
  }
  return result;
};

export const  getFactorCollection = (technology) => { 
  return technology === DEFAULT_TECHNOLOGY ? FACTOR_COLLECTION_5G : FACTOR_COLLECTION_2G;
}