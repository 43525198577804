import rvrConfigType from "../types/rvr-config-type";
import { config } from "../../config/config";
import endpoints from "../../config/endpoints";
import { METRIC_INFO } from "../../config/constants";
import { capitalizeFirstLetter } from "../../utils/general-utils";

// for any user changes on select update this one
export const setUserSelectDict = (selectedData: Object): any => ({
  type: rvrConfigType.SET_USER_SELECTION,
  selectedData,
});

export const removeUserSelectDict = (selectedData: Object): any => ({
  type: rvrConfigType.REMOVE_USER_SELECTION,
  selectedData,
});

export const initRVRLoadingStatus = (): any => ({
  type: rvrConfigType.INIT_LOADING_STATUS,
});

export const postTcsResult = (
  indexNumber: string,
  project: string,
  testCategory: string,
  tcsId: string,
  requestJson: object,
  emptyTestcase: string
) => ({
  type: rvrConfigType.POST_RVR_TCS_RESULT,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.tcsResultURL(testCategory, tcsId),
        method: "POST",
        json: requestJson,
      },
      commit: {
        type: rvrConfigType.POST_RVR_TCS_RESULT_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.DATA,
          project: capitalizeFirstLetter(project),
          testcategory: METRIC_INFO[testCategory],
          indexNumber: indexNumber,
          emptyTestcase: emptyTestcase
        },
      },
      rollback: {
        type: rvrConfigType.POST_RVR_TCS_RESULT_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.DATA,
          project: capitalizeFirstLetter(project),
          testcategory: METRIC_INFO[testCategory],
        },
      },
    },
  },
});

export const getTCSDropdown = (
  indexNumber: string,
  project: string,
  build: string,
  version: string,
  testcategory: string,
  requestId: number | undefined = undefined,
  labId: number | undefined = undefined
) => ({
  type: rvrConfigType.GET_RVR_DEVICE_LIST,
  http: {
    cognito: {
      effect: {
        //TODO change this one to RVR
        url:
          config.BASE_URL +
          endpoints.getDropdownListEndpoint(
            project,
            build,
            version,
            testcategory,
            requestId,
            labId
          ),
        method: "GET",
      },
      commit: {
        type: rvrConfigType.GET_RVR_DEVICE_LIST_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.DATA,
          project: capitalizeFirstLetter(project),
          testcategory: METRIC_INFO[testcategory],
          indexNumber: indexNumber
        },
      },
      rollback: {
        type: rvrConfigType.GET_RVR_DEVICE_LIST_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE,
          feature: METRIC_INFO.DATA,
          project: capitalizeFirstLetter(project),
          testcategory: METRIC_INFO[testcategory],
        },
      },
    },
  },
});

