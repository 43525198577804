import { Select } from "@amzn/awsui-components-react/polaris";
import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import constants, {
  RANGE_CONSTANTS_5G,
  TEST_CATEGORY,
  METRIC_INFO,
  RVR_SELECTION_KEY,
  DROPDOWN_NAMING_COLLECTION,
  RANGE_TYPE_DROPDOWN,
} from "../../../../config/constants";
import {
  setUserSelectDict,
  removeUserSelectDict,
  getTCSDropdown,
  postTcsResult,
  initRVRLoadingStatus
} from "../../../../redux/actions/rvr-config-action";
// ajax
import { getData } from "../../../../utils/cognito-fetch";
import { config } from "../../../../config/config.js";
import endpoint from "../../../../config/endpoints";
import {
  capitalizeFirstLetter,
  getLogData,
} from "../../../../utils/general-utils";
import {
  getSublvlOptions,
  handleLvlOptions,
  mapListOption,
} from "../../utils/dropdown-helper-func";
import { findRangeConstants } from "../../../visualization/rvr/rvr-range-chart/range-calculator-utils";

interface StateProps {
  rvrConfigReducer: any;
}

type Props = {
  dispatch: Dispatch<any>;
  indexNumber: string;
  project: string;
  build: string;
  version: string;
  // external url
  requestId: number;
  labId: number;
} & typeof defaultProps &
  StateProps;


const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  projectOptions: [] as any[],
  // build-version
  buildOptions: [] as any[],
  projectLoadingStatus: constants.LOADING_BEFORE_START,
  buildLoadingStatus: constants.LOADING_BEFORE_START,
  // all projects
  project: "",
  // build_version
  build: "",
  version: "",
  dsn: "",
  firmware: "",
  testitem: "",
  channel: "",
  technology: "",
  link: "",
  range: "",
  rangeCalculator: {
    ...RANGE_CONSTANTS_5G
  },
});

class RVRDropdownList extends Component<Props> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;
  private dropdownRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    const { indexNumber, project, build, version, requestId, labId } =
      this.props;
    this.setState({
      project: project,
      build: build,
      version: version,
    });
    const { selectedData } = this.props.rvrConfigReducer;
    const currentObject = {
      project: project,
      build: build,
      version: version,
    };
    const updateSelected = { ...selectedData };
    updateSelected[indexNumber] = currentObject;
    this.props.dispatch(setUserSelectDict(updateSelected));
    
    this._getProjectOptions();
    this._getBuildOptions(currentObject.project);

    if (requestId && labId) {
      this.props.dispatch(
        getTCSDropdown(
          indexNumber,
          project,
          build,
          version,
          TEST_CATEGORY.RVR,
          requestId,
          labId
        )
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dsn, firmware, testitem, channel, technology, link, range } =
      this.state;
    const { project, build, version, indexNumber , requestId, labId } = this.props;
    const { testcaseDropdown,selectedData } = this.props.rvrConfigReducer;

    if (this.state.project !== prevState.project) {
      this._getBuildOptions(this.state.project);
    }
    const versionChanged = this.state.version !== prevState.version;
    const newVersion = version !== prevProps.version;
    const newProject = project !== prevProps.project;
    const newBuild = build !== prevProps.build;    
    const missingIds = !requestId || !labId;

    if ((versionChanged || newVersion || newProject || newBuild ) && missingIds){
      if(newVersion || newProject || newBuild){
        this.setState({
          project: project,
          build: build,
          version: version,
          dsn: "",
          firmware: "",
          testitem: "",
          channel: "",
          technology: "",
          link: "",
          range: "",
        });
        this.props.dispatch(
          getTCSDropdown(indexNumber, project, build, version, TEST_CATEGORY.RVR)
        )
      }else{        
        this.props.dispatch(
        getTCSDropdown(indexNumber, this.state.project, this.state.build, this.state.version, TEST_CATEGORY.RVR)
        );
      }
      this.props.dispatch(initRVRLoadingStatus());
    }

    if (range && range !== prevState.range) {
      const tcsid = testcaseDropdown[indexNumber]?.[dsn]?.[firmware]?.[testitem]?.[channel]?.[technology]?.[link] ?? "";
      const requestJson = {
        test_case_key: indexNumber,
      };
      this._setSelected("frequency",tcsid["frequency"])
      const rangeConstants = findRangeConstants(technology,tcsid["frequency"]);
      this._setSelected('rangeCalculator', rangeConstants);
      delete selectedData[indexNumber];
      let emptyTestcase = this._findEmptyTestcase(selectedData)

      this.props.dispatch(
        postTcsResult(indexNumber,project, TEST_CATEGORY.RVR, tcsid["id"], requestJson, emptyTestcase)
      );
    }
  }

  // Ajax call for Project options
  _getProjectOptions = () => {
    // change load flag
    this.setState({
      projectLoadingStatus: constants.LOADING_LOAD,
    });

    // ajax call
    getData(
      config.BASE_URL + endpoint.getProjectListWithoutCategoryEndpoint(),
      getLogData(METRIC_INFO.RVR_TESTCASE, METRIC_INFO[TEST_CATEGORY.RVR])
    )
      .then((response) => {
        const projectOptions = response.json.map((item) => ({
          label: capitalizeFirstLetter(item),
          id: item,
        }));
        this.setState({
          projectOptions,
          projectLoadingStatus: constants.LOADING_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          projectLoadingStatus: constants.LOADING_FAIL,
        });
      });
  };

  // Ajax call for build options
  _getBuildOptions = (projectName: string) => {
    const { indexNumber } = this.props;
    // change load flag
    this.setState({
      buildLoadingStatus: constants.LOADING_LOAD,
    });
    const project =
      projectName !== "null"
        ? projectName
        : this.props.rvrConfigReducer.selectedData[indexNumber].project;
    // ajax call
    getData(
      config.BASE_URL + endpoint.getBuildList(project),
      getLogData(METRIC_INFO.RVR_TESTCASE, METRIC_INFO[TEST_CATEGORY.RVR])
    )
      .then((response) => {
        const buildOptions = response.json.map((item) => item);
        this.setState({
          buildLoadingStatus: constants.LOADING_SUCCESS,
          buildOptions,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          buildLoadingStatus: constants.LOADING_FAIL,
        });
      });
  };

  onClickRemoveDropdown = () => {
    const { selectedData } = this.props.rvrConfigReducer;
    const { indexNumber } = this.props;

    const currentTestCastObject = { ...selectedData };

    if (Object.keys(currentTestCastObject).length >= 2) {
      delete currentTestCastObject[indexNumber];
    } else {
      window.alert("The only left one cannot be removed");
    }
    let emptyTestcase = this._findEmptyTestcase(currentTestCastObject)

    if(emptyTestcase){
      this.props.dispatch(setUserSelectDict(currentTestCastObject));
    }else{
      this.props.dispatch(removeUserSelectDict(currentTestCastObject));
    }
  };

  _findEmptyTestcase = (currentTestCastObject: any) => {
    let emptyTestcase = false
    Object.keys(currentTestCastObject).forEach((key)=>{
      if(!currentTestCastObject[key].range || currentTestCastObject[key].range.length === 0){
        emptyTestcase = true;
        return;
      }
    })
    return emptyTestcase
  }
  _dataOnChange = (event: any) => {
    // event.detail {selectedId: "test", selectedOption: {…}}
    const currentValue: string = event.detail.selectedOption.id;
    const key = event.target.id;
    // dynamic add state

    const rvrKeyArray = Object.values(RVR_SELECTION_KEY);
    // checks user changes & clear keys

    const currentCleanArray = rvrKeyArray.slice(rvrKeyArray.indexOf(key) + 1);
    this._clearSelectionArray(currentCleanArray);
    this.setState({
      [key]: currentValue,
    });
    this._setSelected(key, currentValue,currentCleanArray);
    if(key == RVR_SELECTION_KEY.VERSION &&  parseInt(this.props.indexNumber) === 0){
      const { history } = this.props;
      history.replace("/".concat(
          this.state.project,
          "/",
          this.state.build,
          "/",
          this.state.version,
          "/rvr/testcase")); 
    }
  };

  // set in reducer
  _setSelected = (key: any, currentValue: string, cleanArray: any[] = []) => {
    const { selectedData ,tcsResultLoadingStatus } = this.props.rvrConfigReducer;
    const { indexNumber } = this.props;
    // TODO remove tcs infront of subsystem
    let assignValue = currentValue;

    const updateSelected = { ...selectedData };
    updateSelected[indexNumber][key] = assignValue;
    if (Array.isArray(cleanArray)) {
      cleanArray.forEach((key) => {
        updateSelected[indexNumber][key] = null
      });
    }
    this.props.dispatch(setUserSelectDict(updateSelected));
    if(key != RVR_SELECTION_KEY.RANGE && tcsResultLoadingStatus !== constants.LOADING_BEFORE_START){
      this.props.dispatch(initRVRLoadingStatus());
    }
  };

  // array to clean set
  _clearSelectionArray = (cleanArray: Array<string>) => {
    if (Array.isArray(cleanArray)) {
      cleanArray.forEach((key) => {
        this._clearSelection(key);
      });
    }
  };

  _clearSelection = (key: string) => {
    // clear selections
    this.setState({
      [key]: null,
    });
  };

  render() {
    const { indexNumber, requestId, labId } = this.props;
    const {
      // selectedData,
      testcaseDropdown,
      testcaseDropdownLoadingStatus,
    } = this.props.rvrConfigReducer;

    const {
      projectOptions,
      buildOptions,
      // loading status
      projectLoadingStatus,
      buildLoadingStatus,
      // selected value
      project,
      build,
      version,
      dsn,
      firmware,
      testitem,
      channel,
      technology,
      link,
      range,
    } = this.state;

    const buildListOptions = handleLvlOptions(
      buildOptions,
      RVR_SELECTION_KEY.BUILD
    );
    const versionListOption = getSublvlOptions(buildOptions, build);

    const dsnList = mapListOption(Object.keys(testcaseDropdown[indexNumber] ?? []));
    
    const firmwareList = mapListOption(Object.keys(testcaseDropdown[indexNumber]?.[dsn] ?? []));

    const testitemList = mapListOption(Object.keys(testcaseDropdown[indexNumber]?.[dsn]?.[firmware] ?? []));
    
    const channelList = mapListOption(Object.keys(testcaseDropdown[indexNumber]?.[dsn]?.[firmware]?.[testitem] ?? []));

    const technologyList = mapListOption(Object.keys(testcaseDropdown[indexNumber]?.[dsn]?.[firmware]?.[testitem]?.[channel]  ?? []));

    const linkList = mapListOption(Object.keys(testcaseDropdown[indexNumber]?.[dsn]?.[firmware]?.[testitem]?.[channel]?.[technology]  ?? []));

    const rangeList = link ? RANGE_TYPE_DROPDOWN : []

    // config for dropdown
    const mapObject = {
      project: {
        placeholder: DROPDOWN_NAMING_COLLECTION.project,
        options: projectOptions,
        status: projectLoadingStatus,
        select: project,
        disable: requestId && labId ? true : false,
      },
      build: {
        placeholder: DROPDOWN_NAMING_COLLECTION.build,
        options: buildListOptions,
        status: buildLoadingStatus,
        select: build,
        disable: requestId && labId ? true : false,
      },
      version: {
        placeholder: DROPDOWN_NAMING_COLLECTION.version,
        options: versionListOption,
        status: buildLoadingStatus,
        select: version,
        disable: requestId && labId ? true : false,
      },
      dsn: {
        placeholder: DROPDOWN_NAMING_COLLECTION.device,
        options: dsnList,
        status: testcaseDropdownLoadingStatus,
        select: dsn,
      },
      firmware: {
        placeholder: DROPDOWN_NAMING_COLLECTION.firmware,
        options: firmwareList,
        status: testcaseDropdownLoadingStatus,
        select: firmware,
      },
      testitem: {
        placeholder: DROPDOWN_NAMING_COLLECTION.testitem,
        options: testitemList,
        status: testcaseDropdownLoadingStatus,
        select: testitem,
      },
      channel: {
        id: "channel",
        placeholder: DROPDOWN_NAMING_COLLECTION.channel,
        options: channelList,
        status: testcaseDropdownLoadingStatus,
        select: channel,
      },
      technology: {
        placeholder: DROPDOWN_NAMING_COLLECTION.technology,
        options: technologyList,
        status: testcaseDropdownLoadingStatus,
        select: technology,
      },
      link: {
        placeholder: DROPDOWN_NAMING_COLLECTION.link,
        options: linkList,
        status: testcaseDropdownLoadingStatus,
        select: link,
      },
      range: {
        placeholder: DROPDOWN_NAMING_COLLECTION.range,
        options: rangeList,
        status: testcaseDropdownLoadingStatus,
        select: range,
      },
    };

    // map dropdown, based on mapObject above
    const mappedDropdown = Object.keys(mapObject).map((eachKey, index) => {
      return (
        <div key={index} className={"testcase-dropdown-element"}>
          <Select
            id={eachKey}
            empty="No options"
            placeholder={mapObject[eachKey].placeholder}
            selectedLabel="Selected"
            options={mapObject[eachKey].options}
            statusType={
              mapObject[eachKey].status === constants.LOADING_LOAD
                ? constants.SELECT_STATUS_TYPE_LOADING
                : null
            }
            selectedOption={
              mapObject[eachKey].select
                ? {
                    id: mapObject[eachKey].select,
                    label: capitalizeFirstLetter(mapObject[eachKey].select),
                  }
                : null
            }
            disabled={
              mapObject[eachKey].disable ? mapObject[eachKey].disable : false
            }
            onChange={this._dataOnChange}
            filteringType="auto"
          ></Select>
        </div>
      );
    });

    return (
      <div className={"testcase-dropdown-list"} ref={this.dropdownRef}>
        {/* mapped dropdown for rvr based on mapObject above*/}
        {mappedDropdown}
        {indexNumber !== "0" && (
          <button className={"icon-btn"} onClick={this.onClickRemoveDropdown}>
            <i className="fas fa-times fa-lg"></i>
          </button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rvrConfigReducer: state.rvrConfigReducer,
  };
};
export default withRouter(connect<StateProps>(mapStateToProps)(RVRDropdownList));
