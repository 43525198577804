import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  ColumnLayout,
  Button,
  FormField,
  Input,
  ButtonDropdown,
  Alert,
  DatePicker,
  Multiselect,
  Checkbox
} from "@amzn/awsui-components-react/polaris";
import { SpaceBetween, Box, ColumnLayout as ReactColumnLayout} from "@amzn/awsui-components-react-v3";
import { getData, putData, Response } from "../../../utils/cognito-fetch";
import { config } from "../../../config/config.js";
import endpoints from "../../../config/endpoints";
import { getCategoryList } from "../../../redux/actions/category-action";
import { getProjectList } from "../../../redux/actions/project-action";
import {
  getBuildList,
  setNonSigLitepointSelect,
  clearNonSigLitepointSelect,
  setNonSigLitepointVersion,
} from "../../../redux/actions/build-action";
import { getMetadata } from "../../../redux/actions/metadata-action";
import constants, {
  COMPONENT_ID,
  DROPDOWN_DEFAULT,
  METRIC_INFO,
  TEST_CATEGORY,
} from "../../../config/constants";
import "./edit-form.css";
import {
  capitalizeFirstLetter,
  getLogData,
} from "../../../utils/general-utils";
import {
  testCategoryOptions,
  getSelectedOptions,
  validateBuildVersion,
  validateBuild
} from "../build-form-config";
// non sig testcase management
import CoconutTestcaseManagement from "../testcase-manage/coconut-testcase-management";
import LitepointTestcaseManagement from "../testcase-manage/litepoint-testcase-management";
import { showNonSig } from "../testcase-manage/testcase-config";

interface StateProps {
  categoryReducer: any;
  projectReducer: any;
  buildReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  history: any;
} & StateProps;

type State = {
  category: string; // selected category dropdown
  categoryError: string; // input error
  project: string; // selected project dropdown
  projectError: string; // input error
  selectedBuild: string; // selected build dropdown
  selectedBuildError: string; // input error
  testCategory: Array<string>; // selected test category dropdown
  originalTestCategory: Array<string>; // testCategory from selectedBuild
  testCategoryError: string; // input error
  build: string; // user input build
  originalBuild: string; // build name from selectedBuild
  buildError: string; // input error
  version: string; // user input version
  originalVersion: string; // version from selectedBuild
  versionError: string; // input error
  startDate: string; // format "YYYY-MM-DD"
  originalStartDate: string; // startDate from selectedBuild
  startDateError: string; // input error
  endDate: string; // format "YYYY-MM-DD"
  originalEndDate: string; // endDate from selectedBuild
  endDateError: string; // input error
  responseError: string; // response error from BE
  loading: number;
  isWFT: boolean;
};

class BuildForm extends Component<Props, State> {
  readonly state: State = Object.freeze({
    category: DROPDOWN_DEFAULT.CATEGORY,
    categoryError: "",
    project: DROPDOWN_DEFAULT.PROJECT,
    projectError: "",
    selectedBuild: DROPDOWN_DEFAULT.BUILD,
    selectedBuildError: "",
    testCategory: [],
    originalTestCategory: [],
    testCategoryError: "",
    build: "",
    originalBuild: "",
    buildError: "",
    version: "",
    originalVersion: "",
    versionError: "",
    startDate: "",
    originalStartDate: "",
    startDateError: "",
    endDate: "",
    originalEndDate: "",
    endDateError: "",
    responseError: "",
    isWFT: false,
    // loading is only used for showing spinner when call BE, so set default as SUCCESS.
    loading: constants.LOADING_SUCCESS,
  });

  async componentDidMount() {
    // load data for category dropdown
    this.props.dispatch(getCategoryList());

    // get category from create category
    const { category } = this.props.categoryReducer;

    if (category) {
      // prefill dropdown for category
      this.setState({ category });

      // get project list for category
      this.props.dispatch(getProjectList(category));

      // get project from create project
      const { project, projectList } = this.props.projectReducer;

      const projects = projectList.map((project) =>
        capitalizeFirstLetter(project)
      );

      if (project && projects && projects.includes(project)) {
        // prefill dropdown for project
        this.setState({ project });
      }
    }
  }

  //validate user input
  validate = (): boolean => {
    let isValid: boolean = true;

    Object.keys(COMPONENT_ID).forEach((id) => {
      if (!this.validateById(COMPONENT_ID[id])) {
        isValid = false;
      }
    });

    return isValid;
  };

  validateById = (id: string): boolean => {
    let isValid: boolean = true;

    // validate category dropdown
    if (id === COMPONENT_ID.CATEGORY) {
      let categoryError: string = "";
      if (this.state.category === DROPDOWN_DEFAULT.CATEGORY) {
        categoryError = "Required";
        isValid = false;
      }

      this.setState({
        categoryError,
      });
    }

    // validate project dropdown
    if (id === COMPONENT_ID.PROJECT) {
      let projectError: string = "";
      if (this.state.project === DROPDOWN_DEFAULT.PROJECT) {
        projectError = "Required";
        isValid = false;
      }

      this.setState({
        projectError,
      });
    }

    // validate selectedBuild dropdown
    if (id === COMPONENT_ID.SELECTED_BUILD) {
      let selectedBuildError: string = "";
      if (this.state.selectedBuild === DROPDOWN_DEFAULT.BUILD) {
        selectedBuildError = "Required";
        isValid = false;
      }

      this.setState({
        selectedBuildError,
      });
    }

    // validate build
    if (id === COMPONENT_ID.BUILD && this.state.build) {
      const buildError: string = validateBuild(this.state.build);

      this.setState({
        buildError,
      });

      if (buildError) {
        isValid = false;
      }
    }

    // validate version
    if (id === COMPONENT_ID.VERSION && this.state.version) {
      const versionError: string = validateBuildVersion(this.state.version);

      this.setState({
        versionError,
      });

      if (versionError) {
        isValid = false;
      }
    }

    // validate start date
    const startDate = this.state.startDate;
    if (startDate && id === COMPONENT_ID.START_DATE) {
      let startDateError: string = "";

      if (!startDate) {
        startDateError = "Required";
      }

      this.setState({
        startDateError,
      });

      if (startDateError) {
        isValid = false;
      }
    }

    // validate end date
    const endDate = this.state.endDate;
    if (endDate && id === COMPONENT_ID.END_DATE) {
      let endDateError: string = "";

      if (!endDate) {
        endDateError = "Required";
      }

      this.setState({
        endDateError,
      });

      if (endDateError) {
        isValid = false;
      }
    }

    // start date is later than end date
    if (startDate && endDate && startDate.localeCompare(endDate) > 0) {
      const errorMessage: string = "Start date is later than end date";
      this.setState({
        startDateError: errorMessage,
        endDateError: errorMessage,
      });
      isValid = false;
    }

    return isValid;
  };

  _onChange = () => {
    this.setState({ isWFT: !this.state.isWFT });
  }

  /* 
    onClick submit button
    1. validate user input, show input error if find any
    2. sent request to BE
    3. show response to user
      3.1 If failed, show response error
      3.2 If success, ask user choose to continue editing build or not
  */
  _onSubmit = () => {
    // clean up response error from last submit
    this.setState({ responseError: "" });
    const { nonSigCoconutSelect, nonSigLitepointSelect, nonSigLitepointVersion } = this.props.buildReducer;
    const isValid = this.validate();
    if (!isValid) {
      return;
    }

    const category_name: string = this.state.category;
    const project: string = this.state.project;

    // add updated fileds to payload
    const values: {} = {};

    if (this.state.build && this.state.build !== this.state.originalBuild) {
      values["name"] = this.state.build;
    }
    if (
      this.state.version &&
      parseFloat(this.state.version) !== parseFloat(this.state.originalVersion)
    ) {
      values["version"] = this.state.version;
    }
    if (this.state.startDate !== this.state.originalStartDate) {
      values["start_time"] = this.state.startDate;
    }
    if (this.state.endDate !== this.state.originalEndDate) {
      values["end_time"] = this.state.endDate;
    }
    if (
      this.state.testCategory.sort().toString() !==
      this.state.originalTestCategory.sort().toString()
    ) {
      values["test_category_list"] = this.state.testCategory;
    }

    if (this.state.testCategory.includes(TEST_CATEGORY.NON_SIG)) {
      values[constants.NONSIG_POST_KEY] = {
        [constants.NONSIG_COCONUT]: nonSigCoconutSelect,
        [constants.NONSIG_LITEPOINT]: nonSigLitepointSelect,
        [constants.NONSIG_LITEPOINT_VERSION]: nonSigLitepointVersion,
      };
    }

    if (Object.keys(values).length === 0) {
      alert("Nothing changes. Please select a field to edit.");
      return;
    }

    const url =
      config.BASE_URL +
      endpoints.updateBuildList(project, this.state.selectedBuild);

    this.setState({
      loading: constants.LOADING_LOAD,
    });

    putData(
      url,
      values,
      getLogData(METRIC_INFO.BUILD, null, category_name, project)
    ).then((response: Response) => {
      this.setState({
        loading: constants.LOADING_SUCCESS,
      });

      // update side bar data
      this.props.dispatch(getMetadata());

      if (response.ok) {
        const isConfirmed = window.confirm(
          "Successfully updated build. Click OK to Dashboard, cancel to stay on Edit Build."
        );
        if (isConfirmed) {
          // navigate to home page
          this.props.history.push("/");
        } else {
          // stay on create build, clear input
          this.clear();
        }
      } else {
        this.setState({
          responseError: response.json.message,
          loading: constants.LOADING_FAIL,
        });
      }
    });
  };

  clear = () => {
    this.setState({
      category: DROPDOWN_DEFAULT.CATEGORY,
      categoryError: "",
      project: DROPDOWN_DEFAULT.PROJECT,
      projectError: "",
      selectedBuild: DROPDOWN_DEFAULT.BUILD,
      selectedBuildError: "",
      testCategory: [],
      originalTestCategory: [],
      testCategoryError: "",
      build: "",
      originalBuild: "",
      buildError: "",
      version: "",
      originalVersion: "",
      versionError: "",
      startDate: "",
      originalStartDate: "",
      startDateError: "",
      endDate: "",
      originalEndDate: "",
      endDateError: "",
      loading: constants.LOADING_SUCCESS,
    });
  };

  _onChangeInput = (event: any) => {
    const id: string = event.target.id;
    const value: string = event.detail.value;

    if (id === COMPONENT_ID.BUILD) {
      this.setState({
        build: value,
      });
    } else if (id === COMPONENT_ID.VERSION) {
      this.setState({
        version: value,
      });
    }

    // validate user input
    this.validateById(id);
  };

  _onChangeCategory = (event: any) => {
    const category: string = event.detail.id;

    if (category !== this.state.category) {
      this.setState({
        category,
        categoryError: "",
        project: DROPDOWN_DEFAULT.PROJECT,
        projectError: "",
        selectedBuild: DROPDOWN_DEFAULT.BUILD,
        selectedBuildError: "",
        testCategory: [],
        originalTestCategory: [],
        testCategoryError: "",
        build: "",
        originalBuild: "",
        buildError: "",
        version: "",
        originalVersion: "",
        versionError: "",
        startDate: "",
        originalStartDate: "",
        startDateError: "",
        endDate: "",
        originalEndDate: "",
        endDateError: "",
      });

      // load projects for current category
      this.props.dispatch(getProjectList(category));
    }
  };

  _onChangeProject = (event: any) => {
    const project: string = event.detail.id;

    if (project !== this.state.project) {
      this.setState({
        project,
        projectError: "",
        selectedBuild: DROPDOWN_DEFAULT.BUILD,
        selectedBuildError: "",
        testCategory: [],
        originalTestCategory: [],
        testCategoryError: "",
        build: "",
        originalBuild: "",
        buildError: "",
        version: "",
        originalVersion: "",
        versionError: "",
        startDate: "",
        originalStartDate: "",
        startDateError: "",
        endDate: "",
        originalEndDate: "",
        endDateError: "",
      });

      // load builds for current project
      this.props.dispatch(getBuildList(this.state.category, project));
    }
  };

  _onChangeBuild = async (event: any) => {
    const selectedBuild: string = event.detail.id;

    if (selectedBuild !== this.state.selectedBuild) {
      this.setState({
        selectedBuild,
        selectedBuildError: "",
        testCategory: [],
        originalTestCategory: [],
        testCategoryError: "",
        build: "",
        originalBuild: selectedBuild.split("_")[0],
        buildError: "",
        version: "",
        originalVersion: selectedBuild.split("_")[1],
        versionError: "",
        startDate: "",
        originalStartDate: "",
        startDateError: "",
        endDate: "",
        originalEndDate: "",
        endDateError: "",
      });

      // load build info for current build
      const category: string = this.state.category;
      const project: string = this.state.project;
      const url =
        config.BASE_URL +
        endpoints.createBuildEndpoint(project, selectedBuild.toLowerCase());
      const response: Response = await getData(
        url,
        getLogData(METRIC_INFO.BUILD, null, category, project)
      );

      if (response.ok) {
        // fill start date, end date and test categories
        const startDate: string = response.json.start_time
          ? response.json.start_time
          : "";
        const endDate: string = response.json.end_time
          ? response.json.end_time
          : "";
        this.setState({
          startDate,
          originalStartDate: startDate,
          endDate,
          originalEndDate: endDate,
          testCategory: response.json.test_category_list,
          originalTestCategory: response.json.test_category_list,
        });
        // If Nonsig litepoint options returned in response set litepoint reducer to display
        if (response.json?.[constants.NONSIG_POST_KEY]?.[constants.NONSIG_LITEPOINT] !== undefined &&
          response.json?.[constants.NONSIG_POST_KEY]?.[constants.NONSIG_LITEPOINT_VERSION] !== undefined) {
          this.props.dispatch(
            setNonSigLitepointVersion(
              response.json[constants.NONSIG_POST_KEY][constants.NONSIG_LITEPOINT_VERSION]
            )
          );
          this.props.dispatch(
            setNonSigLitepointSelect(
              response.json[constants.NONSIG_POST_KEY][
                constants.NONSIG_LITEPOINT
              ]
            )
          );
        } else {
          this.props.dispatch(clearNonSigLitepointSelect())
        }
          
      } else {
        this.setState({
          responseError: response.json.message,
        });
      }
    }
  };

  _onChangeTestCategory = (event: any) => {
    const testCategory: Array<string> = event.detail.selectedOptions.map(
      (option) => option.id
    );
    this.setState({
      testCategory,
      testCategoryError: "",
    });
  };

  _onChangeDate = (event: any) => {
    const id: string = event.target.id;
    const value: string = event.detail.value;

    if (id === COMPONENT_ID.START_DATE) {
      this.setState({
        startDate: value,
        startDateError: "",
      });
    } else if (id === COMPONENT_ID.END_DATE) {
      this.setState({
        endDate: value,
        endDateError: "",
      });
    }
  };

  getItems = (categoryList: Array<string>): any => {
    const items: Array<ButtonDropdown.Item> = [];

    categoryList.forEach((category) => {
      const item: ButtonDropdown.Item = { id: category, text: category };
      items.push(item);
    });

    return items;
  };

  getTestCategoryOptions = (
    defaultTestCategoryOptions: Array<any>,
    originalTestCategory: Array<string>
  ) => {
    const testCategoryOptions: Array<any> = [];
    defaultTestCategoryOptions.forEach((option) => {
      option["disabled"] = originalTestCategory.includes(option["id"])
        ? true
        : false;
      testCategoryOptions.push(option);
    });
    return testCategoryOptions;
  };

  render() {
    const { categoryList } = this.props.categoryReducer;
    const categoryItems = this.getItems(categoryList);
    const { projectList, loadingStatus: loadingProjectsStatus } =
      this.props.projectReducer;
    const projectItems = this.getItems(projectList);
    const {
      buildList,
      loadingStatus: loadingBuildsStatus,
      canCoconutSubmit,
      canLitepointSubmit,
    } = this.props.buildReducer;

    const buildItems = this.getItems(buildList.sort());

    const options = this.getTestCategoryOptions(
      testCategoryOptions,
      this.state.originalTestCategory
    );

    const showNonSigSession = showNonSig(this.state.testCategory);

    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <h2>Edit Build</h2>
        </div>
        <div>
          {/* main form portion */}
          <div className="d-flex justify-content-start">
            <FormField
              label="Category"
              errorText={this.state.categoryError}
              stretch={true}
            >
              <div className="edit-form-dropdown">
                <ButtonDropdown
                  items={categoryItems}
                  onItemClick={this._onChangeCategory}
                  children={this.state.category}
                ></ButtonDropdown>
              </div>
            </FormField>

            <FormField
              label="Project"
              errorText={this.state.projectError}
              stretch={true}
            >
              <div className="edit-form-dropdown">
                <ButtonDropdown
                  items={projectItems}
                  onItemClick={this._onChangeProject}
                  children={this.state.project}
                  loading={
                    this.state.category !== DROPDOWN_DEFAULT.CATEGORY &&
                    loadingProjectsStatus === constants.LOADING_LOAD
                  }
                ></ButtonDropdown>
              </div>
            </FormField>

            <FormField
              label="Build"
              errorText={this.state.selectedBuildError}
              stretch={true}
            >
              <div className="edit-form-dropdown">
                <ButtonDropdown
                  items={buildItems}
                  onItemClick={this._onChangeBuild}
                  children={this.state.selectedBuild}
                  loading={
                    this.state.category !== DROPDOWN_DEFAULT.CATEGORY &&
                    this.state.project !== DROPDOWN_DEFAULT.PROJECT &&
                    loadingBuildsStatus === constants.LOADING_LOAD
                  }
                ></ButtonDropdown>
              </div>
            </FormField>
          </div>
          <br />

          <ColumnLayout>
            <div style={{ display: "flex" }}>
              <FormField
                label="New Build Stage"
                hintText="Letters only"
                errorText={this.state.buildError}
              >
                <div className="edit-form-input">
                  <Input
                    id={COMPONENT_ID.BUILD}
                    placeholder="HVT, EVT, DVT..."
                    value={this.state.build}
                    onChange={this._onChangeInput}
                  ></Input>
                </div>
              </FormField>

              <FormField
                label="New Version"
                hintText="Numbers only"
                errorText={this.state.versionError}
              >
                <div className="edit-form-input">
                  <Input
                    id={COMPONENT_ID.VERSION}
                    placeholder="1.0"
                    value={this.state.version}
                    onChange={this._onChangeInput}
                  ></Input>
                </div>
              </FormField>
            </div>
          </ColumnLayout>
          <br />

          <ColumnLayout>
            <div style={{ display: "flex" }}>
              <FormField
                label="Update Start Date"
                errorText={this.state.startDateError}
              >
                <div className="edit-form-date-picker">
                  <DatePicker
                    id={COMPONENT_ID.START_DATE}
                    placeholder="YYYY/MM/DD"
                    todayLabel="Today"
                    nextMonthLabel="Next month"
                    previousMonthLabel="Previous month"
                    onChange={this._onChangeDate}
                    value={this.state.startDate}
                  ></DatePicker>
                </div>
              </FormField>

              <FormField
                label="Update End Date"
                errorText={this.state.endDateError}
              >
                <div className="edit-form-date-picker">
                  <DatePicker
                    id={COMPONENT_ID.END_DATE}
                    placeholder="YYYY/MM/DD"
                    todayLabel="Today"
                    nextMonthLabel="Next month"
                    previousMonthLabel="Previous month"
                    onChange={this._onChangeDate}
                    value={this.state.endDate}
                  ></DatePicker>
                </div>
              </FormField>
            </div>
          </ColumnLayout>
          <br />

          <ColumnLayout>
            <div style={{ display: "flex" }}>
              <FormField
                label="Add Test Categories"
                description="Existing test categories are marked as grey!"
                errorText={this.state.testCategoryError}
                stretch={true}
              >
                <div className="edit-form-dropdown">
                  <Multiselect
                    options={options}
                    placeholder="Choose options"
                    checkboxes={true}
                    selectedOptions={getSelectedOptions(
                      this.state.testCategory
                    )}
                    onChange={this._onChangeTestCategory}
                    selectedLabel="Selected"
                  ></Multiselect>
                </div>
              </FormField>
            </div>
          </ColumnLayout>
          <br />

          {this.state.responseError && (
            <React.Fragment>
              <Alert
                header="Errors Detected"
                type="error"
                content={this.state.responseError}
              ></Alert>
              <br />
            </React.Fragment>
          )}
          {showNonSigSession && (
            <div>
              <SpaceBetween size="xs">
                <h3>Non-sig testcase management</h3>
                  <SpaceBetween size="xxl">
                    <div className={"testcase-management-main"}>
                      <h4>WFT</h4>
                      <ReactColumnLayout  columns={2} variant="text-grid">
                        <SpaceBetween size="l">
                          <Checkbox
                            checked={this.state.isWFT}
                            onChange={this._onChange}
                          >  
                          <Box margin={{ bottom: "xxxs" }} color="text-label">
                          Enable WFT
                          </Box>
                          </Checkbox>
                        </SpaceBetween>
                        </ReactColumnLayout>
                    </div>
                    <CoconutTestcaseManagement />
                    <LitepointTestcaseManagement />
                  </SpaceBetween>
              </SpaceBetween>

              <br />
              <Alert
                visible={!this.state.isWFT && !canCoconutSubmit && !canLitepointSubmit}
                header="Known Issues/Limitations"
                type="warning"
              >
                Because you selected non-sig, you must select nonsig testcase
                required values. BT&Lora antenna can leave as blank
              </Alert>
            </div>
          )}

          <br />
          {/* action stripe group */}
          <div className="awsui-util-action-stripe-group">
            <Button onClick={this.clear}>Clear</Button>
            <Button
              variant="primary"
              onClick={this._onSubmit}
              disabled={
                showNonSigSession && !this.state.isWFT && !canCoconutSubmit && !canLitepointSubmit
              }
              loading={this.state.loading === constants.LOADING_LOAD}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categoryReducer: state.categoryReducer,
    projectReducer: state.projectReducer,
    buildReducer: state.buildReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(BuildForm);
