import constants from "../../../../config/constants";

export const getSubOption = (keyObject, infoCollection) => {
  let res: any = [];
  if (!keyObject || !infoCollection) {
    return res;
  }

  const currentKey = keyObject[constants.SELECT_VALUE_KEY];
  if (currentKey && Object.keys(infoCollection).length > 0 && currentKey in infoCollection) {
    res = Object.keys(infoCollection[currentKey]).map((eachSub) => ({
      label: eachSub,
      value: eachSub,
      key: infoCollection[currentKey][eachSub],
    }));
  }

  res.sort((a, b) => {
    if (a.label < b.label) { return -1;  }
    if (a.label > b.label) { return 1; }
    return 0
  })
  
  return res;
};

export const selectIsLoading = (reducerLoadVariable: number): any => {
  // Convert our loading status integers to polaris loading states
  if (reducerLoadVariable === constants.LOADING_LOAD) {
    return constants.POLARIS_SELECT_LOADING_LOAD;
  } else if (reducerLoadVariable === constants.LOADING_FAIL) {
    return constants.POLARIS_SELECT_LOADING_ERROR;
  } else {
    return constants.POLARIS_SELECT_LOADING_SUCCESS;
  }
};

export const getBuildId = (project, build, infoCollection) => {
  let buildId = constants.NONSIG_DEFAULT_BUILD_ID;

  if (project && build && infoCollection) {
    if (Object.keys(infoCollection).length > 0) {
      buildId =
        infoCollection[project[constants.SELECT_VALUE_KEY]][
          build[constants.SELECT_VALUE_KEY]
        ];
    }
  }

  return buildId;
};

export const formatOption = (item) => ({
  label: item,
  value: item,
});

export const formatOptions = (optionsArray) => {
  if (!Array.isArray(optionsArray)) {
    return [];
  }
  return optionsArray.map(formatOption);
};

export const formatTestFormatOption = (item: String) => {
  // Convert 
  if (item.toLowerCase() === "litepoint") {
    return {
      label: "Litepoint",
      value: "litepoint",
    }
  }else if (item.toLowerCase() === "coconut") {
    return {
      label: "Non-Litepoint",
      value: "coconut",
    }
  }else if (item.toLowerCase() === "wft") {
    return {
      label: "WFT",
      value: "wft",
    }
  }
}

export const formatTestFormatOptions = (optionsArray) => {
  // NonSig Dropdown TestFormat BE will return either "coconut or litepoint"
  if (!Array.isArray(optionsArray)) {
    return [];
  }

  const returnArr: any = [];
  if (optionsArray.includes("litepoint")) {
    returnArr.push(formatTestFormatOption("litepoint"));
  } 
  if (optionsArray.includes("coconut")) {
    returnArr.push(formatTestFormatOption("coconut"));
  }
  if (optionsArray.includes("wft")) {
    returnArr.push(formatTestFormatOption("wft"));
  }
  return returnArr
}


interface Params {
  // add params
  [key: string]: string;
}

export const paramFormatter = (params: Params) => {
  let path_project: any | null =
    "project" in params
      ? {
          label: params.project,
          value: params.project,
        }
      : null;
  let path_build_version: any | null =
    "build" in params && "version" in params
      ? {
          label: params.build + "_" + params.version,
          value: params.build + "_" + params.version,
        }
      : null;

  return { path_project, path_build_version };
};
