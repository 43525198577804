import { TEST_STATUS_COLOR_RGB } from "../../../../config/color-config";
import { TEST_STATUS } from "../../../../config/constants";

export const coconutwftLineChartFormat = (dataRateObj) => {
  let series_data: any = []
  const { x_label, y_label, ...seriesData } = dataRateObj;
  const seriesKeys = Object.keys(seriesData);

  seriesKeys.forEach((key) => {
    const { freqs, high_limits, low_limits, results } = seriesData[key]
    series_data.push({
      name: "Power "+key,
      x: freqs,
      y: results,
      mode: "lines+markers",
    }); 
    series_data.push({
      name: "lsl",
      x: freqs,
      y: low_limits,
      mode: "lines",
      line: {
        color: TEST_STATUS_COLOR_RGB[TEST_STATUS.FAILED],
        width: 1,
      }
    });    
    series_data.push({
      name: "usl",
      x: freqs,
      y: high_limits,
      mode: "lines",
      line: {
        color: TEST_STATUS_COLOR_RGB[TEST_STATUS.FAILED],
        width: 1,
      }
    });
  })
  return { x_label: x_label, y_label: y_label, data: series_data };

}