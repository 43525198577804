import batteryConfigType from "../types/battery-config-type";
import constants from "../../config/constants";
import { handleMultiTestcaseData } from "../../utils/general-utils";

const initialState = {
  testcaseDropdown: {},
  testcaseDropdownMessage: "",
  testcaseDropdownLoadingStatus: constants.LOADING_LOAD,

  loadingStatus: constants.LOADING_LOAD,
  tcsResultLoadingStatus: constants.LOADING_BEFORE_START,
  tcsResultMessage: "",

  // Main Object for control all data in BATTERY page and mult-dropdown
  downloadable_link : "",
  axis_datasets: {},

  batteryViewTemplateVersionList: [],
  batteryViewTemplateVersionListLoadingStatus: constants.LOADING_BEFORE_START,

  selectedData: {
    0: {},
  },
};

const batteryConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case batteryConfigType.GET_BATTERY_DEVICE_LIST:
      return {
        ...state,
        testcaseDropdownLoadingStatus: constants.LOADING_LOAD,
      };
    case batteryConfigType.GET_BATTERY_DEVICE_LIST_COMMIT:
      return {
        ...state,
        testcaseDropdownLoadingStatus: constants.LOADING_SUCCESS,
        testcaseDropdown: action.payload,
      };
    case batteryConfigType.GET_BATTERY_DEVICE_LIST_ROLLBACK:
      return {
        ...state,
        testcaseDropdownLoadingStatus: constants.LOADING_FAIL,
        testcaseDropdownMessage: action.payload.message,
      };

    // --------------init loading for clean components -------------
    case batteryConfigType.INIT_LOADING_STATUS:
      return {
        ...state,
        loadingStatus: constants.LOADING_BEFORE_START,
        tcsResultLoadingStatus: constants.LOADING_BEFORE_START,
      };
    // --------------TCS ----------------
    case batteryConfigType.POST_BATTERY_TCS_RESULT:
      return {
        ...state,
        tcsResultLoadingStatus: constants.LOADING_LOAD,
      };
    case batteryConfigType.POST_BATTERY_TCS_RESULT_COMMIT:
      return {
        ...state,
        tcsResultLoadingStatus: constants.LOADING_SUCCESS,
        selectedData: handleMultiTestcaseData(
          state.selectedData,
          action.payload.data,
          action.payload.key
        ),
        axis_datasets: action.payload.axis_datasets,
        tcsResultMessage: action.payload.message,
        downloadable_link: action.payload.downloadable_link
      };
    case batteryConfigType.POST_BATTERY_TCS_RESULT_ROLLBACK:
      return {
        ...state,
        tcsResultLoadingStatus: constants.LOADING_FAIL,
        tcsResultMessage: action.payload.message,
      };

    // --------------View Template Version List---------
    case batteryConfigType.GET_BATTERY_VIEW_TEMPLATE_VERSION_LIST:
      return {
        ...state,
        batteryViewTemplateVersionListLoadingStatus: constants.LOADING_LOAD,
      };
    case batteryConfigType.GET_BATTERY_VIEW_TEMPLATE_VERSION_COMMIT:
      return {
        ...state,
        batteryViewTemplateVersionListLoadingStatus: constants.LOADING_SUCCESS,
        batteryViewTemplateVersionList: action.payload,
      };
    case batteryConfigType.GET_BATTERY_VIEW_TEMPLATE_VERSION_ROLLBACK:
      return {
        ...state,
        batteryViewTemplateVersionListLoadingStatus: constants.LOADING_FAIL
      };

    // --------------control user selection---------
    case batteryConfigType.SET_USER_SELECTION:
      return {
        ...state,
        selectedData: action.selectedData,
      };

    default:
      return state;
  }
};
export default batteryConfigReducer;
