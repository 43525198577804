import React, { Component } from "react";
// redux
import { Dispatch } from "redux";
// directly use plotly
import Plot from "react-plotly.js";
import { connect } from "react-redux";
import { financialToFixed } from "../../../../utils/general-utils";
import { formatDesensChartData, checkEdge } from "./visualization-config";
import "./chartHoverStyle.css";
import constants from "../../../../config/constants";
import { TcsResultResponse } from "../../../../model/http-json";
import { setTcsResult } from "../../../../redux/actions/tcs-result-action"; // redux action

// keys for user concerned value list, for chart presnet helper
const USER_CONCERN_VALUE = {
  baseline: "Baseline",
  desense: "Desense",
  freq: "Frequency",
  diff: "Differ",
};

interface StateProps {
  tcsResultReducer: any;
  desenseConfigReducer: any;
  filterObject: Object;
}

// declare prop check
type Props = {
  filterObject: Object;
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  StateProps;

// declare init state & default props
const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  mainPlotWidth: window.innerWidth,
  // user hovered item, removed click
  currentHover: {} as Object,
  // tcsArray: [] as Object[],
});

class VisualizationChart extends Component<Props> {
  readonly state = initialState;
  private plotRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.plotRef = React.createRef();
  }

  componentDidMount() {
    // handle resize for the visualization
    const desensChartNode = this.plotRef.current;
    if (desensChartNode) {
      this.setState({
        mainPlotWidth: desensChartNode.clientWidth,
        currentClicked: "",
      });
    }
    // handle init new value
  }

  updateWindowDimensions = () => {
    // const node = this.dashboardRightRef.current;
    const desensChartNode = this.plotRef.current;
    if (desensChartNode) {
      this.setState({
        mainPlotWidth: desensChartNode.clientWidth,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    // Add event listener to window resize event
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    this.props.dispatch(
      setTcsResult(constants.LOADING_BEFORE_START, {} as TcsResultResponse)
    );
  }

  onHoverChart = (e: any) => {
    const valueDict = {};
    if (e.points) {
      e.points.forEach((eachTrace: any) => {
        const currentTag =
          eachTrace.curveNumber % 2 === 0
            ? USER_CONCERN_VALUE.baseline
            : USER_CONCERN_VALUE.desense;
        const currentSubObj = {
          [currentTag]: eachTrace.y,
        };
        const freqObj = {
          [USER_CONCERN_VALUE.freq]: eachTrace.x,
        };
        // get the the key in lookup, by the index of each dropdown selected tcs
        const currentKey = eachTrace.data.name
          ? eachTrace.data.name.split(" ").length > 0
            ? eachTrace.data.name.split(" ")[1]
            : null
          : null;

        // add desense and baseline obj in key
        valueDict[currentKey] = {
          ...valueDict[currentKey],
          ...currentSubObj,
        };
        // add frequency in key
        valueDict[currentKey] = {
          ...valueDict[currentKey],
          ...freqObj,
        };
      });

      this.setState({
        currentHover: valueDict,
      });
    }
  };

  render() {
    const { mainPlotWidth, currentHover } = this.state;
    const { filterObject } = this.props;
    // each on hover will trigger render
    // new method here, replace above by below 2 lines after be deploy
    const { selectedData } = this.props.desenseConfigReducer;

    // this input should be array
    const formatedDesense = formatDesensChartData(selectedData);
    // get max and min for both x and y
    const edgeObject = checkEdge(selectedData);

    const layoutObject = {
      // minus 20 px to fit outer container
      width: mainPlotWidth - 20,
      margin: {
        t: 20, //top margin
        b: 30, //bottom margin
      },
      // legend: {
      //   orientation: "h",
      // },
      hoverlabel: { bgcolor: "#FFF" },
      // scrollZoom: true,
      xaxis: {
        title: {
          text: "Frequency(MHz)",
        },
        fixedrange: true,
        range: [
          filterObject["xMin"] === null
            ? edgeObject["xMin"]
            : filterObject["xMin"],
          filterObject["xMax"] === null
            ? edgeObject["xMax"]
            : filterObject["xMax"],
        ],
      },
      yaxis: {
        title: {
          text: "Power(dBm)",
        },
        fixedrange: true,
        range: [
          filterObject["yMin"] === null
            ? edgeObject["yMin"] - 4 // give more room for min limit
            : filterObject["yMin"],
          filterObject["yMax"] === null
            ? edgeObject["yMax"]
            : filterObject["yMax"],
        ],
      },
    };

    const mapUserSelected = Object.keys(currentHover).map((eachKey, index) => (
      <div key={index}>
        {eachKey}:
        <span>
          {USER_CONCERN_VALUE.freq}:{" "}
          {financialToFixed(currentHover[eachKey][USER_CONCERN_VALUE.freq], 3)}
          HMz{" "}
        </span>
        <span>
          {USER_CONCERN_VALUE.baseline}:{" "}
          {financialToFixed(
            currentHover[eachKey][USER_CONCERN_VALUE.baseline],
            3
          )}
          dBM{" "}
        </span>
        <span>
          {USER_CONCERN_VALUE.desense}:{" "}
          {financialToFixed(
            currentHover[eachKey][USER_CONCERN_VALUE.desense],
            3
          )}
          dBM{" "}
        </span>
        <span>
          {USER_CONCERN_VALUE.diff}:{" "}
          {financialToFixed(
            currentHover[eachKey][USER_CONCERN_VALUE.desense] -
              currentHover[eachKey][USER_CONCERN_VALUE.baseline],
            3
          )}{" "}
          dB
        </span>
      </div>
    ));

    return (
      <div className={"desense-main-chart"} ref={this.plotRef}>
        <div className="awsui-util-container">
          {/* <div className="awsui-util-container desense-hover-style">
            {mapUserSelected}
          </div> */}
          <div>
            <Plot
              data={formatedDesense}
              layout={layoutObject}
              onHover={this.onHoverChart}
              // onClick={this.onClickChart}
              config={{ displayModeBar: false }} // not showing mode bar
            />
          </div>

          <div className="awsui-util-container-footer">
            <div>Current hover point : {mapUserSelected}</div>
            {/* current Hover point : {currentHover} */}
          </div>
        </div>

        {/* path title */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tcsResultReducer: state.tcsResultReducer,
    desenseConfigReducer: state.desenseConfigReducer,
  };
};

// export default VisualizationChart;
export default connect<StateProps>(mapStateToProps)(VisualizationChart);
