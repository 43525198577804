export const NONSIG_COMPARE_KEYS = {
  tcs_id: "tcs_id",
  project: "project",
  build: "build",
  config: "config",
  device: "device",
  dataRate: "dataRate",
  power: "power",
  technology: "technology",
  standard: "standard",
  band: "band",
  bandwidth: "bandwidth",
  chain: "chain",
  temperature: "temperature",
  voltage: "voltage",
  test_type: "test_type",
  test_item: "test_item",
  result: "result",
  usl: "usl",
  lsl: "lsl",
};

export const SERIES_OPTIONS = [
  {
    id: NONSIG_COMPARE_KEYS.usl,
    label: "Upper Limits (USL)",
  },
  { id: NONSIG_COMPARE_KEYS.lsl, label: "Lower Limits (LSL)" },
];

export const LEGEND_OPTIONS = [
  { id: NONSIG_COMPARE_KEYS.project, label: "Project" },
  { id: NONSIG_COMPARE_KEYS.build, label: "Build - Version" },
  { id: NONSIG_COMPARE_KEYS.config, label: "Config" },
  { id: NONSIG_COMPARE_KEYS.device, label: "DSN" },
  { id: NONSIG_COMPARE_KEYS.dataRate, label: "Data Rate" },
  { id: NONSIG_COMPARE_KEYS.power, label: "Power" },
  { id: NONSIG_COMPARE_KEYS.technology, label: "Technology" },
  { id: NONSIG_COMPARE_KEYS.standard, label: "Standard" },
  { id: NONSIG_COMPARE_KEYS.band, label: "Band" },
  { id: NONSIG_COMPARE_KEYS.bandwidth, label: "Bandwidth" },
  { id: NONSIG_COMPARE_KEYS.chain, label: "Antenna" },
  { id: NONSIG_COMPARE_KEYS.temperature, label: "Temperature" },
  { id: NONSIG_COMPARE_KEYS.voltage, label: "Voltage" },
  { id: NONSIG_COMPARE_KEYS.test_type, label: "Test Category" },
  { id: NONSIG_COMPARE_KEYS.test_item, label: "Test Item" },
  { id: NONSIG_COMPARE_KEYS.result, label: "Result" },
];

export const DEFAULT_VISIBLE = [
  NONSIG_COMPARE_KEYS.dataRate,
  NONSIG_COMPARE_KEYS.power,
  NONSIG_COMPARE_KEYS.technology,
  NONSIG_COMPARE_KEYS.standard,
  NONSIG_COMPARE_KEYS.band,
  NONSIG_COMPARE_KEYS.bandwidth,
  NONSIG_COMPARE_KEYS.temperature,
  NONSIG_COMPARE_KEYS.voltage,
  NONSIG_COMPARE_KEYS.test_type,
  NONSIG_COMPARE_KEYS.test_item,
];
