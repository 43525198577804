// general constants
const constants = {
  INITIATIVE: "engineering",

  NA: "N/A",
  NULL: "Null",

  SIGNIN: "signIn",
  UNAUTHENTICATED: "unauthenticated",
  AUTHENTICATED: "authenticated",
  SIGNIN_FAILURE: "signIn_failure",
  SIGNOUT: "signOut",
  CUSTOM_OAUTH_STATE: "customOAuthState",

  // These are used for dispatching/listening to custom Hub events.
  SESSION_EXPIRED: "session_expired",
  SESSION_WILL_EXPIRE: "session_will_expire",
  SESSION_TIMER_INTERVAL: 30000,

  // loading status
  LOADING_LOAD: 0,
  LOADING_SUCCESS: 1,
  LOADING_FAIL: -1,
  LOADING_BEFORE_START: 10,

  // general status(we can keep all our status values uniformed)
  // file parse status is using this
  STATUS_SUCCESS: "success",
  STATUS_FAIL: "fail",
  STATUS_ERROR: "error", // there are some legacy status using error
  STATUS_PROCESS: "in_process",
  STATUS_NOT_START: "not_start",

  UPLOAD_CHECK_FILE_SUCCESS: "succeed",
  UPLOAD_CHECK_FILE_FAIL: "failed",

  LANDING_STATUS_PROCESS: "in_process",
  LANDING_STATUS_ARCHIVED: "archived",
  LANDING_STATUS_NOT_START: "not_start",

  TABLE_DEFAULT_MIN_WIDTH: "100px",

  // special constant to show on UI
  RVR: "RvR",
  BATTERY: "Battery",
  // for component Select statusType
  SELECT_STATUS_TYPE_LOADING: "loading" as "loading",

  // non sig related constants
  NONSIG_TEMPERATURE_LIST: ["NT", "HT", "LT"],
  NONSIG_VOLTAGE_LIST: ["NV", "HV", "LV"],
  NONSIG_COCONUT_ANTENNA_LIST: ["0", "1", "2"],
  NONSIG_LITEPOINT_WIFI_ANTENNA_LIST: ["0", "1", "MIMO"],
  NONSIG_LITEPOINT_ZIGBEE_BT_ANT_LIST: ["0", "1"],
  NONSIG_POST_KEY: "non_sig",
  NONSIG_COCONUT: "coconut",
  NONSIG_LITEPOINT: "litepoint",
  NONSIG_WFT: "wft",
  NONSIG_TCS_ID: "tcs_id",
  NONSIG_DSN_CONFIG_ID: "dsn_config_id",
  NONSIG_UPLOAD_TIME: "uploaded_time",
  NONSIG_LITEPOINT_VERSION: "version",
  NONSIG_LITEPOINT_VERSION_DEFAULT: null,

  SELECT_VALUE_KEY: "value",

  NONSIG_DEFAULT_BUILD_ID: -1,

  POLARIS_SELECT_LOADING_LOAD: "loading",
  POLARIS_SELECT_LOADING_ERROR: "error",
  POLARIS_SELECT_LOADING_SUCCESS: "finished",

  CONFLUENCE_LINK: "https://wiki.labcollab.net/confluence/display/WDI/Data+Upload+Templates"
};
export default constants;

// test category
export const TEST_CATEGORY = {
  ANTENNA: "antenna",
  COMPLIANCE: "compliance",
  DESENSE: "desense",
  SIG: "signaling",
  RVR: "rvr",
  BATTERY: "battery",
  NON_SIG: "non-sig",
  LAB_COEX: "lab_coex",
};

export const NONSIG_TEST_LEVEL = {
  TECHNOLOGY: "tech",
  BAND: "band",
  STANDARD: "standard",
  TEMPERATURE: "temp",
  VOLTAGE: "volt",
  BT_ANTENNA: "bt_ant",
  LORA_ANTENNA: "lora_ant",
  ZIGBEE_ANTENNA: "zigbee_ant",
  WIFI_ANTENNA: "wifi_ant",
};

// non sig tech key for nonsig nonlitepoint
export const NONSIG_COCONUT_TECH_KEY = {
  NONSIG_BT: "BT",
  NONSIG_LORA: "LoRa",
  NONSIG_WIFI: "WiFi_legacy",
  NONSIG_WIFI_AX: "WiFi_AX",
};

export const NONSIG_LITEPOINT_TECH_KEY = {
  NONSIG_BT: "BT",
  NONSIG_LORA: "LoRa",
  NONSIG_WIFI: "WiFi",
  NONSIG_ZIGBEE: "ZigBee",
};

// test case map based on hardware knowledge, static, barely change
export const NONSIG_COCONUT_TECH_MAP = {
  [NONSIG_COCONUT_TECH_KEY.NONSIG_WIFI]: {
    "2G": ["11b", "11g", "11n"],
    "5G": ["11a", "11n", "11ac"],
  },
  [NONSIG_COCONUT_TECH_KEY.NONSIG_WIFI_AX]: {
    "2G": ["AX_SU", "AX_ER", "AX_TBT", "AX_MU"],
    "5G": ["AX_SU", "AX_ER", "AX_TBT", "AX_MU"],
  },
  [NONSIG_COCONUT_TECH_KEY.NONSIG_BT]: {
    "2G": ["BDR", "EDR", "BLE"],
  },
  [NONSIG_COCONUT_TECH_KEY.NONSIG_LORA]: {
    "900MHz": ["LoRa", "FSK"],
  },
};

export const NONSIG_LITEPOINT_TECH_MAP = {
  [NONSIG_LITEPOINT_TECH_KEY.NONSIG_WIFI]: {
    "2.4 GHz": ["11b", "11g", "11n", "11ax"],
    "5 GHz": ["11a", "11n", "11ac", "11ax"],
    "6 GHz": ["11ax"],
  },
  [NONSIG_LITEPOINT_TECH_KEY.NONSIG_ZIGBEE]: {
    "2.4 GHz": ["ZigBee"],
  },
  [NONSIG_LITEPOINT_TECH_KEY.NONSIG_BT]: {
    "2.4 GHz": ["BDR", "EDR", "LE"],
  },
  [NONSIG_LITEPOINT_TECH_KEY.NONSIG_LORA]: {
    "900 MHz": ["LoRa"],
  },
};


export const COGESTION_VALUES = {
  singlefamily: 44,
  apartment: 62,
  townhouse: 50,
  "5G": 25,
}

export const FACTOR_COLLECTION_5G = ["5G"]

export const FACTOR_COLLECTION_2G = ["singlefamily","apartment","townhouse"];


// engineering compliance test type
export const ENG_COM_TEST_TYPE = {
  POWER_TABLE: "power_table",
  ACTIVE_SCAN: "active_scan",
  PASSIVE_SCAN: "passive_scan",
  BAND_EDGE: "band_edge",
  HARMONICS: "harmonics",
};

// use to transfer test type from tcs table to standard test type used in plot
export const ENG_COM_TEST_TYPE_PLOT_FOTMATTER = {
  "Power Table": "power_table",
  "Active Scan": "active_scan",
  "Passive Scan": "passive_scan",
  "Band Edge": "band_edge",
  Harmonics: "harmonics",
};

// use to transfer standard test type to regular format shown in tcs table
export const ENG_COM_TEST_TYPE_TABLE_FOTMATTER = {
  power_table: "Power Table",
  active_scan: "Active Scan",
  passive_scan: "Passive Scan",
  band_edge: "Band Edge",
  harmonics: "Harmonics",
};

// test status
export const TEST_STATUS = {
  PASSED: "passed",
  WARNING: "warning",
  FAILED: "failed",
  NO_LIMIT: "no_limit",
  UNTESTED: "untested",
};

export const COUNTRY = {
  US: "us",
  CA: "ca",
  GB: "gb",
  EU: "eu", // Europe
  MX: "mx", // Mexico
  LK: "lk", // Sri Lanka
  AU: "au", // Australia
  NZ: "nz", // New Zealand
  JP: "jp", // Japan
  IN: "in", // india
  BR: "br", // brazil
  WW: "ww", // World Wide
};

// UI text for status
export const STATUS_TEXT = {
  [constants.STATUS_SUCCESS]: "Success",
  [constants.STATUS_FAIL]: "Fail",
  [constants.STATUS_ERROR]: "Fail",
  [constants.STATUS_PROCESS]: "In Progress",
  [constants.STATUS_NOT_START]: "Not Started",
};

// UI text for Landing status
export const LANDING_STATUS_TEXT = {
  [constants.LANDING_STATUS_NOT_START]: "Not Started",
  [constants.LANDING_STATUS_PROCESS]: "In Progress",
  [constants.LANDING_STATUS_ARCHIVED]: "Archived",
};

// Project management component
export const COMPONENT_ID = {
  CATEGORY: "category",
  PROJECT: "project",
  TEST_CATEGORY: "test_category",
  BUILD: "build",
  VERSION: "version",
  START_DATE: "start_date",
  END_DATE: "end_date",
  SELECTED_BUILD: "selected_build",
};

// Project management component
export const DESENSE_COMPONENT_ID = {
  LIMIT: "limit",
  BANDWIDTH_CHANNEL: "bandwidthChannel", // to map state
  TECHNOLOGY: "technology",
  FREQ: "freq",
  BANDWIDTH_CUSTOM: "bandwidthCustom", // to map state
};

// Project management component
export const DROPDOWN_DEFAULT = {
  CATEGORY: "Category",
  PROJECT: "Project",
  BUILD: "Build",
  TECHNOLOGY: "technology",
};

// Report generation
export const REPORT_TYPE = {
  FAILURE_REPORT: "failure_report",
};

export const REPORT_TYPE_TEXT = {
  [REPORT_TYPE.FAILURE_REPORT]: "Failure Report",
};

// User metrics
export const METRIC_INFO = {
  INITIATIVE: "Engineering",
  [TEST_CATEGORY.COMPLIANCE]: "Compliance",
  [TEST_CATEGORY.DESENSE]: "Desense",
  [TEST_CATEGORY.RVR]: "RvR",
  [TEST_CATEGORY.BATTERY]: "Battery",
  [TEST_CATEGORY.ANTENNA]: "Antenna",
  [TEST_CATEGORY.NON_SIG]: "Non-sig",

  RVR_TESTCASE: "RvR Testcase",
  BATTERY_TESTCASE: "Battery Testcase",
  DESENSE_TESTCASE: "Desense Testcase",
  ANTENNA_TESTCASE: "Anetnna Testcase",
  NONSIG_TESTCASE: "Non Sig Testcase",

  LANDING: "Landing Page",
  META_DATA: "Meta Data",
  OVERVIEW: "Project Overview",
  TESTCASE: "TestCases",
  DATA: "Testing Data Visualization",
  BOX_PLOT: "Box Plot",
  DELTA_COMPARE: "Delta Compare",
  REPORT: "Report",
  MANUAL_UPLOAD: "Manual Upload",
  TEMPLATE_DOWNLOAD: "Template Download",
  AUTO_UPLOAD: "Auto Upload",
  CATEGORY: "Categories",
  PROJECT: "Projects",
  BUILD: "Build Stage",
  PROJECT_BUILD_INFO: "Project build info",
  CREATE_CATEGORY: "Create Category",
  CREATE_PROJECT: "Create Project",
  CREATE_BUILD: "Create Build",
  METRIC: "Metric",
  AUTHORIZATION: "Authorization",
  FILTER_PREFERENCES: "Filter",
  EXPORT: "Export",
};

// user metrics status
export const METRICS_STATUS = {
  PASS: "PASS",
  FAIL: "FAIL",
};

// test data keys for desense
export const DESENSE_KEYS = {
  TESTCASE: "tc_name",
  FREQ: "frequency",
  BASELINE: "baseline",
  DESENSE: "desense",
};

// dropdown keys for desense
export const DESENSE_SELECTION_KEY = {
  PROJECT: "project",
  BUILD: "build",
  VERSION: "version",
  CONFIG: "config_dsn",
  FIRMWARE: "firmware",
  SUBSYSTEM: "subsystem",
  ANTENNA: "antenna",
  BAND: "band",
};

// dropdown keys for RVR
export const RVR_SELECTION_KEY = {
  PROJECT: "project",
  BUILD: "build",
  VERSION: "version",
  DSN: "dsn",
  FIRMWARE: "firmware",
  TESTITEM: "testitem",
  CHANNEL: "channel",
  TECHNOLOGY: "technology",
  LINK: "link",
  RANGE: "range",
};

// dropdown keys for Battery
export const BATTERY_SELECTION_KEY = {
  PROJECT: "project",
  BUILD: "build",
  VERSION: "version",
  CONFIG: "config",
  DSN: "dsn",
  TEMPERATURE: "temperature",
  FILE_NAME_TAG: "fileNameTag",
  TEST_CASE_NAME: "testcaseName",
  TEST_CYCLE_TYPE: "testCycleType"
};

export const BATTERY_TITLE = {
  GRAPH_TITLE: "Battery",
  Y_AXIS: "Y-Axis 1",
  Y2_AXIS: "Y-Axis 2",
  X_AXIS: "Elapsed Time"
};

export const BATTERY_DEFAULT_GRAPH_AXIS = {
  x_axis: ["elapsed_time"],
  y2_axis: ["DUT_VBATT","DAQ_VBATT"],
  y_axis: ["DUT_IBATT","DAQ_IBATT"]
}
// Range Type Outdoor for RVR
export const RANGE_TYPE_OUTDOOR = "indoor-outdoor";
export const RANGE_TYPE_INDOOR = "indoor-indoor";

export const DEFAULT_TECHNOLOGY = "5g";

//Range Constants
export const RANGE_CONSTANTS_5G = {
  txGain: -2,
  rxGain: -3,
  desense: 0,
  shadowFading: 10,
  frequency: 2412,
  dutToDutVariation: 2,
  belMedian: 16.6,
  belSigma: 10.6,
  sfSigma: 6,
};

export const RANGE_CONSTANTS_2G = {
  belMedian: 15.2,
  belSigma: 10,
  sfSigma: 5,
};

// dropdown keys for Antenna
export const ANTENNA_SELECTION_KEY = {
  PROJECT: "project",
  BUILD: "build",
  VERSION: "version",
  DSN: "dsn",
};

export const DESENSE_SHORT_SELECTION_KEY = {
  PROJECT: "project",
  BUILD: "buildCombine",
  DEVICE: "device",
  TCS: "tcs",
};

export const TECHNOLOGY = {
  "2G": "2g",
  "5G": "5g",
  BT: "bt",
  ZB: "zb",
  LORA: "lora",
};

// THE KEYS user in desense for table's data
// only have two

export const DESENSE_TABLE_KEYS_DEFAULT = "channelPower";
export const DESENSE_TABLE_KEYS_CUSTOM = "customPower";
export const DESENSE_SPLIT_BY = "_";

// export const DESENSE_COMPARE_LIMIT = 5;
// export const RVR_COMPARE_LIMIT = 5;
export const DEFAULT_DROPDOWN_LIMIT = 5;

export const DEFAULT_DESENSE_LIMIT = "3";

export const DROPDOWN_NAMING_COLLECTION = {
  project: "Project",
  build: "Build",
  version: "Version",
  config: "Config",
  device: "DSN",
  firmware: "Firmware",
  testitem: "Testitem",
  channel: "Channel",
  technology: "Technology",
  link: "Link",
  range: "Range",
  temperature: "Temperature",
  fileNameTag: "File Name Tag",
  testcaseName: "Testcase Name",
  voltage: "Voltage",
  testCycleType: "FG Cycle-Type",
};

export const ANTENNA_ACTIVE_KEY_LIST = ["active", "trp", "tis"];

// list for test categories have test case page instead of overview page
export const DIRECT_TESTCASE_LIST = [
  TEST_CATEGORY.DESENSE,
  TEST_CATEGORY.RVR,
  TEST_CATEGORY.BATTERY,
  TEST_CATEGORY.ANTENNA,
  TEST_CATEGORY.NON_SIG, // will remove this here in nonsig p2 dev is there
];


export const RANGE_TYPE_DROPDOWN = [{"label":"Indoor-Indoor","id":"Indoor-Indoor"},{"label":"Indoor-Outdoor","id":"Indoor-Outdoor"}]

export const RANGE_CONSTANT_TITLE = "Range Constants";

export const RANGE_CONSTANT_ERROR = {
  "txGain":"txGainError",
  "rxGain":"rxGainError",
  "desense":"desenseError",
  "shadowFading":"shadowFadingError",
  "frequency":"frequencyError",
  "dutToDutVariation":"dutToDutVariationError",
  "belMedian":"belMedianError",
  "belSigma":"belSigmaError",
  "sfSigma":"sfSigmaError"
}

export const RANGE_CONSTANT = {
  "technology": {
    "label": "Technology",
    "description": "",
    "title": "",
    "range": ""
  },
  "txGain": {
    "label": "Average Antenna Gain AP (Tx)",
    "description": "dB",
    "title": "Avg Tx",
    "range": ""
  },
  "rxGain": {
    "label": "Average Antenna Gain Client (Rx)",
    "description": "dB",
    "title": "Avg Rx",
    "range": ""
  },
  "sfSigma": {
    "label": "SF σ",
    "description": "dB",
    "title": "SF σ",
    "range": "indoor-outdoor"
  },
  "belSigma": {
    "label": "BEL σ",
    "description": "dB",
    "title": "BEL σ",
    "range": "indoor-outdoor"
  },
  "dutToDutVariation": {
    "label": "DUT to DUT variation",
    "description": "dB",
    "title": "DUT to DUT variation",
    "range": "indoor-outdoor"
  },
  "belMedian": {
    "label": "BEL Median",
    "description": "dB",
    "title": "BEL Median",
    "range": "indoor-outdoor"
  },
  "desense": {
    "label": "2G noise impact (desense)",
    "description": "dB",
    "title": "Desense",
    "range": "indoor-indoor"
  },
  "shadowFading": {
    "label": "Shadow fading",
    "description": "dB",
    "title": "Shadow fading",
    "range": "indoor-indoor"
  },
  "frequency": {
    "label": "Frequency",
    "description": "MHz",
    "title": "Frequency",
    "range": ""
  }
}

//Plotly Graph Layout 

export const RVR_GRAPH_LAYOUT = {
  title: {
    text: "",
    font: {
      size: 15
    },
    x: 0.1,
  },
  annotations: [],
  width: 0,
  margin: {
    t: 40,
    b: 70
  },
  yaxis: { 
    title: "",
    showspikes: true
  },
  xaxis: {
    title: {
      text: "",
      standoff: 0.5,
    },
    rangemode: "tozero",
    showspikes: true,
    spikesnap: "data"
  },
  legend: {
    orientation: 'h'
  },
};

export const RVR_WATERMARK_ANNOTATION = {
  xref: "paper",
  yref: "paper",
  x: 0.3,
  y: 0.5,
  layer: "below",
  font: {
    size: 50
  },
  text: 'CDS-Lab126',
  sizing: "stretch",
  opacity: 0.2,
  textangle: '-45',
  showarrow: false
}

export const RVR_RANGE_CONSTANT_WATERMARK_ANNOTATION = {
  xref: "paper",
  yref: "paper",
  x: 0,
  y: 0.9,
  layer: "below",
  align: "left",
  font: {
    size: 15,
    color: "blue"
  },
  text: "",
  sizing: "stretch",
  opacity: 0.3,
  showarrow: false
}

export const RVR_GRAPH_CONFIG =  {
  displayModeBar: true,
  editable: true,
  toImageButtonOptions: {
    filename: ""
  }
}