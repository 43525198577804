import nonsigConfigType from "../types/nonsig-config-type";
import constants from "../../config/constants";
import { litepointLineChartFormat } from "../../containers/visualization/nonsig/line-chart/nonsig-litepoint-line-chart-config";
import { coconutwftLineChartFormat } from "../../containers/visualization/nonsig/line-chart/nonsig-coconut-line-chart-config";
import { NONSIG_COMPARE_KEYS } from "../../containers/visualization/nonsig/line-chart/comparison-chart/comparison-constants";

const initialState = {
  configDsnObj: {},
  configDsnObjMessage: "",
  configDsnObjLoadingStatus: constants.LOADING_BEFORE_START,

  tcsTableData: [],
  tcsTableDataErrorMessage: "",
  tcsTableDataLoadingStatus: constants.LOADING_BEFORE_START,

  tcsResult: {},
  tcsResultMessage: "",
  tcsResultLoadingStatus: constants.LOADING_BEFORE_START,

  tcsSelection: [],
  testFormat: "",

  comparisonPlot: [],
  comparisonPlotMetadata: [],
  comparisonTable: [],

  selectedProject: null,
  selectedBuild: null,
  selectedConfig: null,
  selectedDevice: null,
};

// Process from selecting device up to viewing data is an ordered process
// At any point you reset to one step in the process, reset all following steps too
const resetConfigDsnObj = () => ({
  // Reset all state for holding Config DSN
  configDsnObj: {},
  configDsnObjMessage: "",
  configDsnObjLoadingStatus: constants.LOADING_BEFORE_START,
  ...resetTestFormat(), // without ConfigDSN, there should not be a selected test format
});

const resetTestFormat = () => ({
  // Reset the selected test format
  testFormat: "",
  ...resetTableData(), // without a selected test format, there should not be any table data
});

const resetTableData = () => ({
  // Reset all table data
  tcsTableData: [],
  tcsTableDataErrorMessage: "",
  tcsTableDataLoadingStatus: constants.LOADING_BEFORE_START,
  tcsSelection: [],
  ...resetResultChartData(), // without TCS table, should be no chart
});

const resetResultChartData = () => ({
  // Reset all TCS chart data
  tcsResult: {},
  tcsResultMessage: "",
  tcsResultLoadingStatus: constants.LOADING_BEFORE_START,
});

// Functions for Comparisons
const addSelectedTestForComparison = (state) => {
  /* Removes selected test item from Test Table + Plots
   *
   * @param state: Redux State
   * returns Object containing states table, plot Data, plot metadata without deleted items
   */

  const {
    tcsResult,
    tcsResultLoadingStatus,
    testFormat,
    tcsSelection,

    comparisonPlot,
    comparisonPlotMetadata,
    comparisonTable,

    selectedProject,
    selectedBuild,
    selectedConfig,
    selectedDevice,
  } = state;

  // 1) Verification that we have valid data to add

  // 1a. Verify selected row is a valid Litepoint result that does have test data
  if (
    !tcsResult ||
    tcsResultLoadingStatus !== constants.LOADING_SUCCESS ||
    Object.keys(tcsResult).length === 0 ||
    tcsSelection.length === 0 ||
    !selectedProject ||
    !selectedBuild ||
    !selectedConfig ||
    !selectedDevice
  ) {
    return {};
  }

  // 1b. Verify existing row is not added in table
  const currentSelect = tcsSelection[0];

  // 2: Format casing + decimal values of PBV Config/DSN for consistency
  const formattedProject = selectedProject.toUpperCase();

  // Format Build Version to have decimal formatting
  const [buildName, buildVersion] = selectedBuild.split("_");
  let stringVersion = parseFloat(buildVersion.trimEnd()).toLocaleString();
  if (stringVersion.indexOf(".") === -1) {
    stringVersion = stringVersion.concat(".0");
  }
  const formattedBuild = buildName.toUpperCase().concat(" ", stringVersion);
  const formattedConfig = selectedConfig.toLowerCase();
  const formattedDevice = selectedDevice.toUpperCase();

  // 3. Create entry for Comparison Table
  const newTableEntry = {
    id: comparisonTable.length,
    tcs_id: currentSelect["tcs_id"],
    pbv: formattedProject.concat(" - ", formattedBuild),
    configDevice: formattedConfig.concat(" - ", formattedDevice),
    test: "".concat(
      currentSelect["technology"],
      " ",
      currentSelect["standard"],
      " ",
      currentSelect["band"],
      " ",
      // Not all items have bandwidth, use "noBW" as placeholder
      currentSelect["bandwidth"]
        ? currentSelect["bandwidth"].concat(" ")
        : "noBW ",
      "ant",
      currentSelect["chain"] ?? currentSelect["tx_chain"] ?? "",
      " ",
      currentSelect["temperature"],
      " ",
      currentSelect["voltage"],
      " ",
      currentSelect["test_type"] ?? "",
      " ",
      currentSelect["test_item"] ??  currentSelect["sub_technology"] ?? ""  
    ),
    result: currentSelect["result"],
    upload_time: currentSelect["upload_time"]
  };
  if (
    comparisonTable.findIndex((item) => item["tcs_id"] === currentSelect["tcs_id"]) !== -1
  ) {
    // Item exists in table, exit already
    return {};
  }
  // 4. Create entry for Plot Metadata
  // Metadata needs to be kept as KV pairs for allowing custom legend names for user
  const chartMetadataEntry = (dataRate, seriesName) => ({
    [NONSIG_COMPARE_KEYS.tcs_id]: currentSelect["tcs_id"],
    [NONSIG_COMPARE_KEYS.dataRate]: dataRate,
    // LP series name is the power
    [NONSIG_COMPARE_KEYS.power]: seriesName,
    [NONSIG_COMPARE_KEYS.project]: formattedProject,
    [NONSIG_COMPARE_KEYS.build]: formattedBuild,
    [NONSIG_COMPARE_KEYS.config]: formattedConfig,
    [NONSIG_COMPARE_KEYS.device]: formattedDevice,
    [NONSIG_COMPARE_KEYS.technology]: currentSelect["technology"],
    [NONSIG_COMPARE_KEYS.standard]: currentSelect["standard"],
    [NONSIG_COMPARE_KEYS.band]: currentSelect["band"],
    // Not all items have bandwidth, use "noBW" as placeholder
    [NONSIG_COMPARE_KEYS.bandwidth]: currentSelect["bandwidth"]
      ? currentSelect["bandwidth"]
      : "noBW",
    [NONSIG_COMPARE_KEYS.chain]: "ant".concat(testFormat === constants.NONSIG_LITEPOINT ? currentSelect["chain"] : currentSelect["tx_chain"]),
    [NONSIG_COMPARE_KEYS.temperature]: currentSelect["temperature"],
    [NONSIG_COMPARE_KEYS.voltage]: currentSelect["voltage"],
    [NONSIG_COMPARE_KEYS.test_type]: currentSelect["test_type"],
    [NONSIG_COMPARE_KEYS.test_item]: currentSelect["test_item"],
    [NONSIG_COMPARE_KEYS.result]: currentSelect["result"],
  });
 
  // 5. Iterate through all series
  const newSeriesPlotData: Object[] = [];
  const newSeriesMetadata: Object[] = [];

  Object.keys(tcsResult).forEach((eachRate, index) => {
    // Process data from TCS into X,Y form
    let data; 
    if(testFormat == constants.NONSIG_LITEPOINT){
      ({ data} = litepointLineChartFormat(tcsResult[eachRate]));
    }else{
      ({ data } = coconutwftLineChartFormat(tcsResult[eachRate]));
    }
    // Push plot + metadata item onto array
    data.forEach((series_data) => {
      newSeriesMetadata.push(chartMetadataEntry(eachRate, series_data["name"]));
      newSeriesPlotData.push(series_data);
    });
  });

  // 6. Return state values concatinated with newly added data
  return {
    comparisonPlot: comparisonPlot.concat(newSeriesPlotData),
    comparisonPlotMetadata: comparisonPlotMetadata.concat(newSeriesMetadata),
    comparisonTable: comparisonTable.concat([newTableEntry]),
  };
};

const removeSelectedTestsForComparison = (state, selectedItems) => {
  /* Removes selected test item from Test Table + Plots
   *
   * @param state: Redux State
   * @param selectedItems: items to remove
   * returns Object containing states table, plot Data, plot metadata without deleted items
   */

  // Create set of Testcases to remove for easy look up
  const tcsRemove = new Set(selectedItems.map((item) => item.tcs_id));

  const newMetadataValues: string[] = [];
  const newPlotValues: Object[] = [];

  // Remove plot data + metadata
  state.comparisonPlotMetadata.forEach((item, index) => {
    if (!tcsRemove.has(item.tcs_id)) {
      newPlotValues.push(state.comparisonPlot[index]);
      newMetadataValues.push(item);
    }
  });

  // Remove data from Table and reset unique indices for React
  const newComparisonTable = state.comparisonTable
    .filter((item) => !tcsRemove.has(item.tcs_id))
    .map((item, index) => ({
      ...item,
      id: index,
    }));

  return {
    comparisonPlot: newPlotValues,
    comparisonPlotMetadata: newMetadataValues,
    comparisonTable: newComparisonTable,
  };
};

const nonsigReducer = (state = initialState, action) => {
  switch (action.type) {
    // --------- Clear state operations ----------------
    case nonsigConfigType.CLEAR_NONSIG_DEVICE_LIST:
      return {
        ...state,
        ...resetConfigDsnObj(), // reset config dsn object
      };
    case nonsigConfigType.CLEAR_TEST_FORMAT:
      return {
        ...state,
        ...resetTestFormat(), // reset config dsn object
      };
    // --------- Config DSN data for a selected PBV ----------------
    case nonsigConfigType.GET_NONSIG_DEVICE_LIST:
      return {
        ...state,
        ...resetConfigDsnObj(), // reset config dsn object since we are fetching a new one
        configDsnObjLoadingStatus: constants.LOADING_LOAD,
      };
    case nonsigConfigType.GET_NONSIG_DEVICE_LIST_COMMIT:
      return {
        ...state,
        configDsnObjLoadingStatus: constants.LOADING_SUCCESS,
        configDsnObj: action.payload,
      };
    case nonsigConfigType.GET_NONSIG_DEVICE_LIST_ROLLBACK:
      return {
        ...state,
        configDsnObjLoadingStatus: constants.LOADING_FAIL,
        deviceObjMessage: action?.payload?.message,
      };

    // ------------- Test Status Table data ----------------
    case nonsigConfigType.NONSIG_TCS_STATUS_RESULT:
      return {
        ...state,
        ...resetTableData(), // reset TCS table data since we are fetching new data
        ...resetResultChartData(), // since no table or selection, reset Chart data too
        tcsTableDataLoadingStatus: constants.LOADING_LOAD,
      };
    case nonsigConfigType.NONSIG_TCS_STATUS_RESULT_COMMIT:
      return {
        ...state,
        tcsTableDataLoadingStatus: constants.LOADING_SUCCESS,
        tcsTableData: action.payload.map((row, i) => ({
          tcs_id: i, // LP doesn't have unique tcs id but non-lp does
          ...row,
        })),
        selectedProject: action.project,
        selectedBuild: action.build,
        selectedConfig: action.config,
        selectedDevice: action.device,
      };
    case nonsigConfigType.NONSIG_TCS_STATUS_RESULT_ROLLBACK:
      return {
        ...state,
        tcsTableDataLoadingStatus: constants.LOADING_FAIL,
        tcsTableDataErrorMessage: action?.payload?.message,
        tcsTableData: [],
        selectedProject: null,
        selectedBuild: null,
        selectedConfig: null,
        selectedDevice: null,
      };

    // -------------- Result Chart Data ----------------
    case nonsigConfigType.POST_NONSIG_TCS_RESULT:
      return {
        ...state,
        ...resetResultChartData(), // reset chart data since we are fetching new data
        tcsResultLoadingStatus: constants.LOADING_LOAD,
      };
    case nonsigConfigType.POST_NONSIG_TCS_RESULT_COMMIT:
      return {
        ...state,
        tcsResultLoadingStatus: constants.LOADING_SUCCESS,
        tcsResult: action.payload,
      };
    case nonsigConfigType.POST_NONSIG_TCS_RESULT_ROLLBACK:
      return {
        ...state,
        tcsResultLoadingStatus: constants.LOADING_FAIL,
        tcsResultMessage: action?.payload?.message,
      };

    // --------------control user selection---------
    case nonsigConfigType.SET_TCS_SELECTION:
      return {
        ...state,
        tcsSelection: action.tcsSelection,
      };

    case nonsigConfigType.SET_TEST_FORMAT:
      return {
        ...state,
        testFormat: action.testFormat,
      };

    // ---- Chart Comparison
    case nonsigConfigType.ADD_TEST_FOR_COMPARISON:
      return {
        ...state,
        ...addSelectedTestForComparison(state),
      };
    case nonsigConfigType.REMOVE_TESTS_FOR_COMPARISON:
      return {
        ...state,
        ...removeSelectedTestsForComparison(state, action.selectedItems),
      };

    default:
      return state;
  }
};

export default nonsigReducer;
