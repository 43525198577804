import React, { Component } from "react";
// redux
import { Dispatch } from "redux";
// directly use plotly
import Plot from "react-plotly.js";
import { connect } from "react-redux";
import { 
  getFormatRangeCalculateResult , 
  getFormatOutdoorRangeCalculateResult,
  compareObjectValues,
  getFactorCollection,
  breakAfter100Chars } from "./range-calculator-utils";
import { Button, Icon, Input } from "@amzn/awsui-components-react/polaris";
import { 
  RANGE_TYPE_OUTDOOR, 
  RANGE_CONSTANT_TITLE,
  RANGE_CONSTANT,
  COGESTION_VALUES,
  FACTOR_COLLECTION_5G,
  FACTOR_COLLECTION_2G,
  RVR_GRAPH_LAYOUT,
  RVR_WATERMARK_ANNOTATION,
  RVR_GRAPH_CONFIG,
  RVR_RANGE_CONSTANT_WATERMARK_ANNOTATION
} from "../../../../config/constants";
import "./range-chart-style.css";

interface StateProps {
  rvrConfigReducer: any;
}

// declare prop check
type Props = {
  filterObject: Object;
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  StateProps;

// declare init state & default props
const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  mainPlotWidth: window.innerWidth,
  xTitle: "range(m)",
  yTitle: "thoughput(Mbps)",
  xMin: null,
  xMax: null,
  yMin: null,
  yMax: null,
  factorCollection: COGESTION_VALUES,
  xScale: null,
  yScale: null
});

class RvRRangeChart extends Component<Props> {
  readonly state = initialState;
  private plotRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.plotRef = React.createRef();
  }

  componentDidMount() {
    // handle resize for the visualization
    const rvrChartNode = this.plotRef.current;
    if (rvrChartNode) {
      this.setState({
        mainPlotWidth: rvrChartNode.clientWidth,
      });
    }
    // handle init new value
  }

  updateWindowDimensions = () => {
    // const node = this.dashboardRightRef.current;
    const rvrChartNode = this.plotRef.current;
    if (rvrChartNode) {
      this.setState({
        mainPlotWidth: rvrChartNode.clientWidth,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    // Add event listener to window resize event
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  _resetInput = () => {
    this.setState({
      xMin: null,
      xMax: null,
      yMin: null,
      yMax: null,
      factorCollection: COGESTION_VALUES,
      xScale: null,
      yScale: null
    });
  };

  getFormattedData = () => {
    const { selectedData } = this.props.rvrConfigReducer

    const { commonValues, uncommonValues } = compareObjectValues(selectedData);

    let title = breakAfter100Chars(RANGE_CONSTANT_TITLE + "("+commonValues.join("_")+")")

    let formattedData = [];
    let annotations = [RVR_WATERMARK_ANNOTATION]
    let x = 1;
    Object.keys(selectedData).forEach((eachTC) => {
      let currentFormatDataArray = []
      let rangeConstants = "Testcase "+eachTC +": <br>"
      Object.keys(selectedData[eachTC]["rangeCalculator"]).forEach((key) => {
        if(RANGE_CONSTANT[key]["range"].length === 0 || RANGE_CONSTANT[key]["range"] === selectedData[eachTC]["range"]?.toLowerCase()){
          rangeConstants += RANGE_CONSTANT[key]["title"]+ ": "+selectedData[eachTC]["rangeCalculator"][key]+" ("+RANGE_CONSTANT[key]["description"]+")<br>"
        }
      })
      let annotation = RVR_RANGE_CONSTANT_WATERMARK_ANNOTATION
      annotation["x"] = x
      annotation["text"] = rangeConstants
      annotations.push(annotation)
      x -= 0.15
      let congestionValues;
      const range = selectedData[eachTC]["range"]?.toLowerCase();
      const technology = selectedData[eachTC]["technology"]?.toLowerCase();
      
      if (range === RANGE_TYPE_OUTDOOR) {
          congestionValues = getFactorCollection(technology);
      } else {
          congestionValues = [...FACTOR_COLLECTION_5G, ...FACTOR_COLLECTION_2G];
      }

      let factorCollection = {}
      congestionValues.forEach((collection) => {
        factorCollection[collection] = this.state.factorCollection[collection]
      })
      if (selectedData[eachTC]["range"]?.toLowerCase() === RANGE_TYPE_OUTDOOR) {
        currentFormatDataArray = getFormatOutdoorRangeCalculateResult(
          selectedData[eachTC],
          eachTC,
          uncommonValues,
          factorCollection
          );

      } else {
        currentFormatDataArray = getFormatRangeCalculateResult(
            selectedData[eachTC],
            eachTC,
            uncommonValues,
            factorCollection
          );
      }
      formattedData = formattedData.concat(currentFormatDataArray);
    })
    return { formattedData, annotations, title } 
  };

  

  render() {  
    /*
    For more details on how to add annotations to Plotly charts, see the official Plotly documentation:
    * https://plotly.com/javascript/text-and-annotations/
    */
    const {formattedData, annotations, title} = this.getFormattedData()
    const { selectedData } = this.props.rvrConfigReducer

    let layout = RVR_GRAPH_LAYOUT
    layout["title"]["text"] = title
    layout["annotations"] = annotations
    layout["width"] = this.state.mainPlotWidth - 20
    layout["yaxis"]["title"] = this.state.yTitle
    layout["xaxis"]["title"]["text"] = this.state.xTitle
    
    if(this.state.xScale){
      layout["xaxis"]["autotick"] = false
      layout["xaxis"]["dtick"] = this.state.xScale
    }
    if(this.state.yScale){
      layout["yaxis"]["autotick"] = false
      layout["yaxis"]["dtick"] = this.state.yScale
    }

    let config = RVR_GRAPH_CONFIG
    config["toImageButtonOptions"]["filename"] = title


    const ranges = [...new Set(Object.values(selectedData).map(tcs => tcs.range?.toLowerCase()))];
    const technologies = [...new Set(Object.values(selectedData).map(tcs => tcs.technology?.toLowerCase()))];
    let congestionValues;
    if (ranges.length === 1 && ranges.includes(RANGE_TYPE_OUTDOOR) && technologies.length === 1) {
        congestionValues = getFactorCollection(technologies[0]);
    } else {
        congestionValues = [...FACTOR_COLLECTION_5G, ...FACTOR_COLLECTION_2G];
    }
    const congestionValuesInput = congestionValues.map((eachKey, index) => (
      <div className={"axis-dropdown-element-congestion"} key={index+1}>
        <div className={"axis-dropdown-label"}>{eachKey}: </div>
        <div className={"axis-dropdown-input"}>
          <Input
              onChange={({ detail }) => {
                this.setState((prevState) => ({
                  factorCollection : {
                    ...prevState['factorCollection'],
                    [eachKey]: detail.value,
                  }
                }))
              }}
              value={this.state.factorCollection[eachKey].toString()}
              inputMode="decimal"
              invalid={this.state.factorCollection[eachKey] < 0 || this.state.factorCollection[eachKey] > 100 }
              type="number"
            />
          </div>
      </div>
    ));
    const scaleMapping = [
      {
        "label": "X-Axis",
        "value": this.state.xScale,
        "key": "xScale"
      },
      {
        "label": "Y-Axis",
        "value": this.state.yScale,
        "key": "yScale"
      }
    ]

    const axisScaleInput = scaleMapping.map((axis, index) => (
      <div className={"axis-dropdown-element-congestion"} key={index+"-scale"}>
          <div className={"axis-dropdown-label"}>{axis["label"]}: </div>
          <div className={"axis-dropdown-input"}>
            <Input
                onChange={({ detail }) => {
                  this.setState({
                    [axis["key"]] : detail.value
                  })
                }}
                value={axis["value"] || "0" }
                inputMode="numeric"
                type="number"
              />
            </div>
      </div>
    ));

    return (
      <div className={"rvr-range-chart"} ref={this.plotRef}>
        <div className="awsui-util-container">
          <div>
            <Plot
              data={formattedData}
              layout={layout}
              config={config} // not showing mode bar
            />
          </div>
          <div className="visual-reset-btn">
            <Button onClick={this._resetInput}>
              <Icon name="undo" size="normal" variant="normal" />
            </Button>
          </div>
        </div>
        <div className={"axis-select-component"}>
            <div className={"axis-dropdown-element-congestion"} key={0}>
              <div className={"axis-dropdown-label"}>Congestion Values in % :  </div>
            </div>
            {congestionValuesInput}
        </div>
        <div className={"axis-select-component"}>
            <div className={"axis-dropdown-element-congestion"} key={"0-scale"}>
              <div className={"axis-dropdown-label"}>Change scale of axis:  </div>
            </div>
            {axisScaleInput}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rvrConfigReducer: state.rvrConfigReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(RvRRangeChart);
