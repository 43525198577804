import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  ColumnLayout,
  Button,
  FormField,
  Input,
  ButtonDropdown,
  Alert,
  DatePicker,
  Multiselect,
  Checkbox
} from "@amzn/awsui-components-react/polaris";
import { SpaceBetween, Box, ColumnLayout as ReactColumnLayout } from "@amzn/awsui-components-react-v3";
import { getData, postData, Response } from "../../../utils/cognito-fetch";
import { config } from "../../../config/config.js";
import endpoints from "../../../config/endpoints";
import { getCategoryList } from "../../../redux/actions/category-action";
import { getProjectList } from "../../../redux/actions/project-action";
import { getMetadata } from "../../../redux/actions/metadata-action";
import constants, {
  COMPONENT_ID,
  DROPDOWN_DEFAULT,
  METRIC_INFO,
  TEST_CATEGORY,
} from "../../../config/constants";
import "./create-form.css";
import {
  capitalizeFirstLetter,
  getLogData,
} from "../../../utils/general-utils";
import {
  testCategoryOptions,
  getSelectedOptions,
  validateBuildVersion,
  validateBuild
} from "../build-form-config";

// non sig testcase management
import CoconutTestcaseManagement from "../testcase-manage/coconut-testcase-management";
import LitepointTestcaseManagement from "../testcase-manage/litepoint-testcase-management";
import { showNonSig } from "../testcase-manage/testcase-config";

interface StateProps {
  categoryReducer: any;
  projectReducer: any;
  buildReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  history: any;
} & StateProps;

type State = {
  category: string; // selected category dropdown
  categoryError: string; // input error
  project: string; // selected project dropdown
  projectError: string; // input error
  testCategory: Array<string>; // selected test category dropdown
  testCategoryError: string; // input error
  build: string; // user input build
  buildError: string; // input error
  version: string; // user input version
  versionError: string; // input error
  startDate: string; // format "YYYY-MM-DD"
  startDateError: string; // input error
  endDate: string; // format "YYYY-MM-DD"
  endDateError: string; // input error
  responseError: string; // response error from BE
  loading: number;
  isWFT: boolean;
};

class BuildForm extends Component<Props, State> {
  readonly state: State = Object.freeze({
    category: DROPDOWN_DEFAULT.CATEGORY,
    categoryError: "",
    project: DROPDOWN_DEFAULT.PROJECT,
    projectError: "",
    testCategory: [],
    testCategoryError: "",
    build: "",
    buildError: "",
    version: "",
    versionError: "",
    startDate: "",
    startDateError: "",
    endDate: "",
    endDateError: "",
    responseError: "",
    isWFT: false,
    // loading is only used for showing spinner when call BE, so set default as SUCCESS.
    loading: constants.LOADING_SUCCESS,
  });

  async componentDidMount() {
    // load data for category dropdown
    this.props.dispatch(getCategoryList());

    // get category from create category
    const { category } = this.props.categoryReducer;

    if (category) {
      // prefill dropdown for category
      this.setState({ category });

      // load data for project dropdown asyc
      this.props.dispatch(getProjectList(category));

      // get project from create project
      const { project } = this.props.projectReducer;

      // get project list for category
      const url = config.BASE_URL + endpoints.getProjectListEndpoint(category);
      const response: Response = await getData(
        url,
        getLogData(METRIC_INFO.BUILD, null, category, project)
      );
      const projects = response.json.map((project) =>
        capitalizeFirstLetter(project)
      );

      if (project && projects && projects.includes(project)) {
        // prefill dropdown for project
        this.setState({ project });
      }
    }
  }

  //validate user input
  validate = (): boolean => {
    let isValid: boolean = true;

    Object.keys(COMPONENT_ID).forEach((id) => {
      if (!this.validateById(COMPONENT_ID[id])) {
        isValid = false;
      }
    });

    return isValid;
  };

  validateById = (id: string): boolean => {
    let isValid: boolean = true;

    // validate category
    if (id === COMPONENT_ID.CATEGORY) {
      let categoryError: string = "";
      if (this.state.category === DROPDOWN_DEFAULT.CATEGORY) {
        categoryError = "Required";
        isValid = false;
      }

      this.setState({
        categoryError,
      });
    }

    // validate project
    if (id === COMPONENT_ID.PROJECT) {
      let projectError: string = "";
      if (this.state.project === DROPDOWN_DEFAULT.PROJECT) {
        projectError = "Required";
        isValid = false;
      }

      this.setState({
        projectError,
      });
    }

    // validate test category
    if (id === COMPONENT_ID.TEST_CATEGORY) {
      let testCategoryError: string = "";
      if (this.state.testCategory.length === 0) {
        testCategoryError = "Required";
        isValid = false;
      }

      this.setState({
        testCategoryError,
      });
    }

    // validate build
    if (id === COMPONENT_ID.BUILD) {
      const buildError: string = validateBuild(this.state.build);

      this.setState({
        buildError,
      });

      if (buildError) {
        isValid = false;
      }
    }

    // validate version
    if (id === COMPONENT_ID.VERSION) {
      const versionError: string = validateBuildVersion(this.state.version);

      this.setState({
        versionError,
      });

      if (versionError) {
        isValid = false;
      }
    }

    // validate start date
    const startDate = this.state.startDate;
    if (id === COMPONENT_ID.START_DATE) {
      let startDateError: string = "";

      if (!startDate) {
        startDateError = "Required";
      }

      this.setState({
        startDateError,
      });

      if (startDateError) {
        isValid = false;
      }
    }

    // validate end date
    const endDate = this.state.endDate;
    if (id === COMPONENT_ID.END_DATE) {
      let endDateError: string = "";

      if (!endDate) {
        endDateError = "Required";
      }

      this.setState({
        endDateError,
      });

      if (endDateError) {
        isValid = false;
      }
    }

    // start date is later than end date
    if (startDate && endDate && startDate.localeCompare(endDate) > 0) {
      const errorMessage: string = "Start date is later than end date";
      this.setState({
        startDateError: errorMessage,
        endDateError: errorMessage,
      });
      isValid = false;
    }

    return isValid;
  };

  _onChange = () => {
    this.setState({ isWFT: !this.state.isWFT });
  }

  /* 
    onClick submit button
    1. validate user input, show input error if find any
    2. sent request to BE
    3. show response to user
      3.1 If failed, show response error
      3.2 If success, ask user choose to continue creating build or not
  */
  _onSubmit = () => {
    // clean up response error from last submit
    this.setState({ responseError: "" });
    const {
      nonSigCoconutSelect,
      nonSigLitepointSelect,
      nonSigLitepointVersion,
    } = this.props.buildReducer;

    const isValid = this.validate();
    if (!isValid) {
      return;
    }

    const category_name: string = this.state.category;
    const project: string = this.state.project;
    const build: string = `${this.state.build}_${this.state.version}`;
    const test_categories: Array<string> = this.state.testCategory;
    const start_time: string = this.state.startDate;
    const end_time: string = this.state.endDate;
    const values: {} = {
      category_name,
      test_categories,
      start_time,
      end_time,
    };
    if (this.state.testCategory.includes(TEST_CATEGORY.NON_SIG)) {
      values[constants.NONSIG_POST_KEY] = {
        [constants.NONSIG_COCONUT]: nonSigCoconutSelect,
        [constants.NONSIG_LITEPOINT]: nonSigLitepointSelect,
        [constants.NONSIG_LITEPOINT_VERSION]: nonSigLitepointVersion,
      };
    }
    
    const url = config.BASE_URL + endpoints.createBuildEndpoint(project, build);

    this.setState({
      loading: constants.LOADING_LOAD,
    });

    postData(
      url,
      values,
      getLogData(METRIC_INFO.BUILD, null, category_name, project)
    ).then((response: Response) => {
      this.setState({
        loading: constants.LOADING_SUCCESS,
      });

      // update side bar data
      this.props.dispatch(getMetadata());

      if (response.ok) {
        const isConfirmed = window.confirm(
          "Successfully created build. Click OK to Dashboard, cancel to stay on Create Build."
        );
        if (isConfirmed) {
          // navigate to home page
          this.props.history.push("/");
        } else {
          // stay on create build, clear input
          this.clear();
        }
      } else {
        this.setState({
          responseError: response.json.message,
          loading: constants.LOADING_FAIL,
        });
      }
    });
  };

  clear = () => {
    this.setState({
      category: DROPDOWN_DEFAULT.CATEGORY,
      categoryError: "",
      project: DROPDOWN_DEFAULT.PROJECT,
      projectError: "",
      testCategory: [],
      testCategoryError: "",
      build: "",
      buildError: "",
      version: "",
      versionError: "",
      startDate: "",
      startDateError: "",
      endDate: "",
      endDateError: "",
      responseError: "",
      loading: constants.LOADING_FAIL,
    });
  };

  _onChangeInput = (event: any) => {
    const id: string = event.target.id;
    const value: string = event.detail.value;

    if (id === COMPONENT_ID.BUILD) {
      this.setState({
        build: value,
      });
    } else if (id === COMPONENT_ID.VERSION) {
      this.setState({
        version: value,
      });
    }

    // validate user input
    this.validateById(id);
  };

  _onChangeCategory = (event: any) => {
    const category: string = event.detail.id;

    if (category !== this.state.category) {
      this.setState({
        category,
        categoryError: "",
        project: DROPDOWN_DEFAULT.PROJECT,
      });

      // load projects for current category
      this.props.dispatch(getProjectList(category));
    }
  };

  _onChangeProject = (event: any) => {
    const project: string = event.detail.id;

    if (project !== this.state.project) {
      this.setState({
        project,
        projectError: "",
      });
    }
  };

  _onChangeTestCategory = (event: any) => {
    const testCategory: Array<string> = event.detail.selectedOptions.map(
      (option) => option.id
    );
    this.setState({
      testCategory,
      testCategoryError: "",
    });
  };

  _onChangeDate = (event: any) => {
    const id: string = event.target.id;
    const value: string = event.detail.value;

    if (id === COMPONENT_ID.START_DATE) {
      this.setState({
        startDate: value,
        startDateError: "",
      });
    } else if (id === COMPONENT_ID.END_DATE) {
      this.setState({
        endDate: value,
        endDateError: "",
      });
    }
  };

  getItems = (categoryList: Array<string>): any => {
    const items: Array<ButtonDropdown.Item> = [];

    categoryList.forEach((category) => {
      const item: ButtonDropdown.Item = { id: category, text: category };
      items.push(item);
    });

    return items;
  };

  render() {
    const { categoryList } = this.props.categoryReducer;
    const categoryItems = this.getItems(categoryList);
    const { projectList, loadingStatus: loadingProjectsStatus } =
      this.props.projectReducer;
    const projectItems = this.getItems(projectList);
    const { canCoconutSubmit, canLitepointSubmit } = this.props.buildReducer;
    const showNonSigSession = showNonSig(this.state.testCategory);

    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <h2>Create Build</h2>
        </div>
        <div>
          {/* main form portion */}
          <div className="d-flex justify-content-start">
            <FormField
              label="Category"
              errorText={this.state.categoryError}
              stretch={true}
            >
              <div className="create-form-dropdown">
                <ButtonDropdown
                  items={categoryItems}
                  onItemClick={this._onChangeCategory}
                  children={this.state.category}
                ></ButtonDropdown>
              </div>
            </FormField>

            <FormField
              label="Project"
              errorText={this.state.projectError}
              stretch={true}
            >
              <div className="create-form-dropdown">
                <ButtonDropdown
                  items={projectItems}
                  onItemClick={this._onChangeProject}
                  children={this.state.project}
                  loading={
                    this.state.category !== DROPDOWN_DEFAULT.CATEGORY &&
                    loadingProjectsStatus === constants.LOADING_LOAD
                  }
                ></ButtonDropdown>
              </div>
            </FormField>
          </div>
          <br />

          <ColumnLayout>
            <div style={{ display: "flex" }}>
              <FormField
                label="Build Stage"
                hintText="Letters only"
                errorText={this.state.buildError}
              >
                <div className="create-form-input">
                  <Input
                    id={COMPONENT_ID.BUILD}
                    placeholder="HVT, EVT, DVT..."
                    value={this.state.build}
                    onChange={this._onChangeInput}
                  ></Input>
                </div>
              </FormField>

              <FormField
                label="Version"
                hintText="Numbers only"
                errorText={this.state.versionError}
              >
                <div className="create-form-input">
                  <Input
                    id={COMPONENT_ID.VERSION}
                    placeholder="1.0"
                    value={this.state.version}
                    onChange={this._onChangeInput}
                  ></Input>
                </div>
              </FormField>
            </div>
          </ColumnLayout>
          <br />

          <ColumnLayout>
            <div style={{ display: "flex" }}>
              <FormField
                label="Start Date"
                errorText={this.state.startDateError}
              >
                <div className="create-form-date-picker">
                  <DatePicker
                    id={COMPONENT_ID.START_DATE}
                    placeholder="YYYY/MM/DD"
                    todayLabel="Today"
                    nextMonthLabel="Next month"
                    previousMonthLabel="Previous month"
                    onChange={this._onChangeDate}
                    value={this.state.startDate}
                  ></DatePicker>
                </div>
              </FormField>

              <FormField label="End Date" errorText={this.state.endDateError}>
                <div className="create-form-date-picker">
                  <DatePicker
                    id={COMPONENT_ID.END_DATE}
                    placeholder="YYYY/MM/DD"
                    todayLabel="Today"
                    nextMonthLabel="Next month"
                    previousMonthLabel="Previous month"
                    onChange={this._onChangeDate}
                    value={this.state.endDate}
                  ></DatePicker>
                </div>
              </FormField>
            </div>
          </ColumnLayout>
          <br />

          <ColumnLayout>
            <div style={{ display: "flex" }}>
              <FormField
                label="Test Categories"
                errorText={this.state.testCategoryError}
                stretch={true}
              >
                <div className="create-form-dropdown">
                  <Multiselect
                    options={testCategoryOptions}
                    placeholder="Choose options"
                    checkboxes={true}
                    selectedOptions={getSelectedOptions(
                      this.state.testCategory
                    )}
                    onChange={this._onChangeTestCategory}
                    selectedLabel="Selected"
                  ></Multiselect>
                </div>
              </FormField>
            </div>
          </ColumnLayout>
          <br />

          {this.state.responseError && (
            <React.Fragment>
              <Alert
                header="Errors Detected"
                type="error"
                content={this.state.responseError}
              ></Alert>
              <br />
            </React.Fragment>
          )}

          {showNonSigSession && (
            <div>
              <SpaceBetween size="xs">
                <h3>Non-sig testcase management</h3>
                <div className={"testcase-management-main"}>
                  <h4>WFT</h4>
                  <ReactColumnLayout  columns={2} variant="text-grid">
                    <SpaceBetween size="l">
                      <Checkbox
                        checked={this.state.isWFT}
                        onChange={this._onChange}
                      >  
                      <Box margin={{ bottom: "xxxs" }} color="text-label">
                      Enable WFT
                      </Box>
                      </Checkbox>
                    </SpaceBetween>
                    </ReactColumnLayout>
                </div>
                <CoconutTestcaseManagement />
                <LitepointTestcaseManagement />
              </SpaceBetween>
              <br />
              <Alert
                visible={!this.state.isWFT && !canCoconutSubmit && !canLitepointSubmit}
                header="Known Issues/Limitations"
                type="warning"
              >
                Because you selected non-sig, you must fill in one of coconut or
                litepoint nonsig testcase's required values.
              </Alert>
            </div>
          )}
          <br />
          <br />

          {/* action stripe group */}
          <div className="awsui-util-action-stripe-group">
            <Button onClick={this.clear}>Clear</Button>
            <Button
              variant="primary"
              onClick={this._onSubmit}
              disabled={
                showNonSigSession && !this.state.isWFT && !canCoconutSubmit && !canLitepointSubmit
              }
              loading={this.state.loading === constants.LOADING_LOAD}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categoryReducer: state.categoryReducer,
    projectReducer: state.projectReducer,
    buildReducer: state.buildReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(BuildForm);
