import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, Alert } from "@amzn/awsui-components-react-v3";
import { useHistory, useParams } from "react-router-dom";
import {
  getTCSDropdown,
  getStatusTable,
  setTestFormat,
  clearConfigDsnDropdown,
  clearTestTypeDropdown,
} from "../../../../redux/actions/nonsig-config-action";
import {
  formatOptions,
  getSubOption,
  paramFormatter,
  getBuildId,
  selectIsLoading,
  formatTestFormatOptions,
  formatTestFormatOption,
} from "./dropdown-utils";
import constants from "../../../../config/constants";

const NonSigDropdownList = () => {
  const dispatch = useDispatch();
  const URLParams = useParams();
  const { path_project, path_build_version } = paramFormatter(URLParams);
  const history = useHistory();

  const { metadataReducer, nonsigReducer } = useSelector((state: any) => ({
    metadataReducer: state.metadataReducer,
    nonsigReducer: state.nonsigReducer,
  }));
  const {
    configDsnObj,
    configDsnObjMessage,
    configDsnObjLoadingStatus,
    tcsTableDataLoadingStatus,
    tcsResultLoadingStatus,
    testFormat,
  } = nonsigReducer;
  const { infoCollection, infoLoadingStatus, infoMessage } = metadataReducer;
  // dropdown info collection

  const [project, setProject] = useState(path_project);
  const [buildVersion, setBuildVersion] = useState<null | any>(
    path_build_version
  );
  const [config, setConfig] = useState<null | any>(null);
  const [dsn, setDSN] = useState<null | any>(null);

  const projectOptions = infoCollection
    ? Object.keys(infoCollection).sort()
    : [];
  const buildOptions = getSubOption(project, infoCollection);
  const configOptions = configDsnObj ? Object.keys(configDsnObj).sort() : [];
  const dsnOptions = config ? getSubOption(config, configDsnObj) : [];
  const testFormatOptions = dsn?.key ? Object.keys(dsn.key) : [];

  // Disable select when request is inflight
  // Prevent user changing input and then request returns with data for prior input
  const disableSelect: boolean =
    tcsTableDataLoadingStatus === constants.LOADING_LOAD ||
    tcsResultLoadingStatus === constants.LOADING_LOAD;

  // Keep track of URL to no-op useEffect if URLParams changes reference, but not PBV values
  const url = useRef({ project: "", buildVersion: "" }); // init to empty str so component mount cycle runs

  useEffect(() => {
    // Invoked either by URLParams object changed
    const { path_project, path_build_version } = paramFormatter(URLParams);

    // Do not do anything if the Project Build Version values or URLParams have not changed
    if (
      path_project[constants.SELECT_VALUE_KEY] === url.current.project &&
      path_build_version[constants.SELECT_VALUE_KEY] ===
        url.current.buildVersion
    ) {
      return;
    }

    // Store values of current project & build from URL for future comparion
    url.current.project = path_project["value"];
    url.current.buildVersion = path_build_version["value"];

    // Update states
    setProject(path_project);
    setBuildVersion(path_build_version);
    setDSN(null);
    setConfig(null);

    // Invoke get TCS, which also clears TestFormat, TCS table, & Plot chart
    const currProject = path_project[constants.SELECT_VALUE_KEY];
    const [currBuild, currVers] =
      path_build_version[constants.SELECT_VALUE_KEY].split("_");
    dispatch(getTCSDropdown(currProject, currBuild, currVers));
  }, [URLParams, dispatch]);

  const onChangeProject = ({ detail }) => {
    // Do not do anything if the same project is selected
    if (
      project &&
      project[constants.SELECT_VALUE_KEY] ===
        detail.selectedOption[constants.SELECT_VALUE_KEY]
    ) {
      return;
    }

    setProject(detail.selectedOption);
    setBuildVersion(null);
    setDSN(null);
    setConfig(null);
    // Clear ConfigDSN dropdown since build is not selected
    // This redux action also clears TestFormat, TCS table, & plot chart
    dispatch(clearConfigDsnDropdown());
  };

  const onChangeBuild = ({ detail }) => {
    // Build changed, change URL
    const [currBuild, currVers] =
      detail.selectedOption[constants.SELECT_VALUE_KEY].split("_");
    history.push(
      "/".concat(
        project[constants.SELECT_VALUE_KEY],
        "/",
        currBuild,
        "/",
        currVers,
        "/non-sig/testcase"
      )
    );
  };

  const onChangeConfig = ({ detail }) => {
    setConfig(detail.selectedOption);
    setDSN(null); // clear DSN on Config change
    dispatch(clearTestTypeDropdown());
  };

  const onChangeDSN = ({ detail }) => {
    setDSN(detail.selectedOption);
    dispatch(clearTestTypeDropdown());
  };

  const onChangeTestFormat = ({ detail }) => {
    dispatch(setTestFormat(detail.selectedOption.value));

    // Load coconut or litepoint TCS data
    const currProject = project[constants.SELECT_VALUE_KEY];
    const currBuild = buildVersion[constants.SELECT_VALUE_KEY];
    let currConfig;
    let currDSN;
    let dbKey;

    if ([constants.NONSIG_COCONUT, constants.NONSIG_WFT].includes(detail.selectedOption.value)) {
      dbKey = getBuildId(project, buildVersion, infoCollection);
      currConfig = config[constants.SELECT_VALUE_KEY];
      currDSN = dsn[constants.SELECT_VALUE_KEY];
    } else if (detail.selectedOption.value === constants.NONSIG_LITEPOINT) {
      dbKey = "".concat(currProject, "_", currBuild);
      [currConfig, currDSN] = dsn?.key[detail.selectedOption.value].split("_");
    }

    dispatch(
      getStatusTable(
        currProject,
        currBuild,
        currConfig,
        currDSN,
        detail.selectedOption.value,
        dbKey
      )
    );
  };

  return (
    <div>
      {/* mapped dropdown here */}
      <div className={"testcase-dropdown-list"}>
        <div className={"testcase-dropdown-element"}>
          <Select
            id={"project"}
            empty="No options"
            placeholder={"Project"}
            options={formatOptions(projectOptions)}
            statusType={selectIsLoading(infoLoadingStatus)}
            selectedOption={project as any}
            onChange={onChangeProject}
            filteringType="auto"
            disabled={disableSelect}
          />
        </div>
        <div className={"testcase-dropdown-element"}>
          <Select
            id={"buildVerion"}
            empty="No options"
            placeholder={"Build_Version"}
            options={buildOptions}
            statusType={selectIsLoading(infoLoadingStatus)}
            selectedOption={buildVersion as any}
            onChange={onChangeBuild}
            filteringType="auto"
            disabled={disableSelect}
          />
        </div>
        <div className={"testcase-dropdown-element"}>
          <Select
            id={"config"}
            empty="No options"
            placeholder={"config"}
            options={formatOptions(configOptions)}
            statusType={selectIsLoading(configDsnObjLoadingStatus)}
            selectedOption={config}
            onChange={onChangeConfig}
            filteringType="auto"
            disabled={disableSelect}
          />
        </div>
        <div className={"testcase-dropdown-element"}>
          <Select
            id={"DSN"}
            empty="No options"
            placeholder={"DSN"}
            options={dsnOptions}
            statusType={selectIsLoading(configDsnObjLoadingStatus)}
            selectedOption={dsn}
            onChange={onChangeDSN}
            filteringType="auto"
            disabled={disableSelect}
          />
        </div>
        <div className={"testcase-dropdown-element"}>
          <Select
            id={"testTypeDropdown"}
            empty="No options"
            placeholder={"test format"}
            options={formatTestFormatOptions(testFormatOptions)}
            statusType={selectIsLoading(configDsnObjLoadingStatus)}
            selectedOption={testFormat && formatTestFormatOption(testFormat)}
            onChange={onChangeTestFormat}
            filteringType="auto"
            disabled={disableSelect}
          />
        </div>
      </div>
      {/* error messages */}
      {configDsnObjLoadingStatus === constants.LOADING_FAIL ||
        (infoLoadingStatus === constants.LOADING_FAIL && (
          <div>
            <Alert dismissAriaLabel="Close alert" type="warning">
              {infoMessage && infoMessage?.length > 0
                ? infoMessage.concat(" ")
                : ""}
              {configDsnObjMessage && configDsnObjMessage.length > 0
                ? configDsnObjMessage
                : ""}
            </Alert>
          </div>
        ))}
    </div>
  );
};
export default NonSigDropdownList;
