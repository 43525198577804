import React, {useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { connect } from "react-redux";
import styled from "styled-components";
import {
  Spinner,
  Icon,
  Alert,
  Button
} from "@amzn/awsui-components-react/polaris";
import constants from "../../../config/constants";
// child components
import BreadcrumbGroup from "../../../components/breadcrumb-group";
// sub components
import BatteryDropdownList from  "./dropdown/dropdown-list";
import BatteryVisualization from "../../visualization/battery/battery-visualization";
// redux action
import {
  setUserSelectDict,
  initBatteryLoadingStatus,
} from "../../../redux/actions/battery-config-action";


const StyledButtonDiv = styled.div`
  padding-bottom: 30px;
`;

const BatteryTestcase = () => {
  const dispatch = useDispatch();
  const { project, build, version } = useParams();


  const batteryConfigReducer = useSelector((state: any) => state.batteryConfigReducer);
  const { tcsResultLoadingStatus, tcsResultMessage, selectedData, downloadable_link } = batteryConfigReducer;

  useEffect(() => {
    return () => {
      const cleanSelectedData = {};
      dispatch(setUserSelectDict(cleanSelectedData));
      const initSelectedData = {
        0: {},
      };
      dispatch(setUserSelectDict(initSelectedData));
      dispatch(initBatteryLoadingStatus());
    };
  }, [dispatch]);

  const mapMultiDropdown = Object.keys(selectedData).map((eachKey, index) => (
    <div key={index}>
      Testcase :
      <BatteryDropdownList
        indexNumber={eachKey}
        project={project}
        build={build}
        version={version}
      />
    </div>
  ));

  return (
    <div>
      <BreadcrumbGroup
        textGroup={[
          { text: 'Dashboard', href: '/' },
          { text: `Battery View` }, // TestCategory
        ]}
      />
      <br />
      {mapMultiDropdown}
      
      {constants.LOADING_BEFORE_START === tcsResultLoadingStatus && (
        <>
          <br/>
          <div className="desense-notice-info">
            Click submit & load the visualization
          </div>
        </>
      )}
      {constants.LOADING_LOAD === tcsResultLoadingStatus && (
        <div>
          <Spinner size="large" />
        </div>
      )}
      {constants.LOADING_SUCCESS === tcsResultLoadingStatus && (
        <div>
          {tcsResultMessage.includes('not') ? (
            <div>
              <Icon name="status-negative" size="normal" variant="error" />
              {tcsResultMessage}
            </div>
          ) : (
            <>
              <StyledButtonDiv>
                <div className="Btn-right-bottom float-right">
                  <Button className="right-button"
                    onClick={() => { window.open(downloadable_link, "_blank"); }}
                  >
                    Download Results
                  </Button>
                </div>
                <br/>
              </StyledButtonDiv>
              <BatteryVisualization />
            </>
          )}
        </div>
      )}
      {constants.LOADING_FAIL === tcsResultLoadingStatus && (
        <div>
          <Alert type="error" header="Fetch data failed">
            Loading Failed. {tcsResultMessage}
          </Alert>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    batteryConfigReducer: state.batteryConfigReducer,
  };
};

// Connect the component to the Redux store
export default connect(mapStateToProps)(BatteryTestcase);
